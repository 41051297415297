import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { UpSquareOutlined } from '@ant-design/icons';
import { Button, message } from 'antd';
import * as Yup from 'yup';

import {
  FileType,
  SubventionSchemaAccessType,
  SubventionSpendingMode,
  SubventionType,
} from '@graphql/generated/types';

import { FormInput } from '@components/atoms/FormInput/FormInput';
import { FormItem } from '@components/atoms/FormItem/FormItem';
import { FormPicture } from '@components/atoms/FormPicture/FormPicture';
import { FormSelect } from '@components/atoms/FormSelect/FormSelect';
import { FormTextArea } from '@components/atoms/FormTextArea/FormTextArea';
import {
  ChildFormProps,
  Form,
  FormProps,
  useForm,
} from '@components/organisms/Form/Form';
import { Card } from '@atoms/Card/Card';

import styles from './SubventionSchemaCreateForm.module.css';

import {
  SubventionSchemaCreateFormMutation,
  SubventionSchemaCreateFormMutationVariables,
  useSubventionSchemaCreateFormMutation,
  useSubventionSchemaCreateParentLazyQuery,
} from './SubventionSchemaCreateForm.generated';

export type SubventionSchemaCreateFormValues = {
  parentId?: string;
  name: string;
  iconId?: string;
  type: SubventionType[];
  accessType: SubventionSchemaAccessType;
  spendingModes: SubventionSpendingMode[];
  reimbursementPolicy?: string;
  legalNoticeFr?: string | null;
  legalNoticeEn?: string | null;
  percentReimbursementNoticeFr?: string | null;
  percentReimbursementNoticeEn?: string | null;
};

export const legalNoticeFrPlaceholder = `Conformément à la position de l’URSSAF, l'utilisation de cette subvention est restreinte aux enseignes commercialisant exclusivement des produits culturels et aux biens ou prestations culturelles des enseignes non spécialisées dans les produits culturels. 

Aussi, en utilisant cette subvention, vous certifiez sur l'honneur que l'utilisation de cette subvention se fera exclusivement dans le cas d'achats d'articles liés à la culture et aux loisirs.`;

export const legalNoticeEnPlaceholder = `In accordance with the URSSAF position, the use of this benefit is restricted to shops marketing exclusively cultural products and cultural goods or services not specialized in cultural products. 

Also, by using this benefit, you hereby certify that the use of this grant will be made exclusively in the case of purchases of items related to culture and entertainment.`;

export const percentReimbursementNoticeFrPlaceholder = `Selon les conditions de cette subvention, le remboursement en pourcentage est requis par l'URSSAF.`;

export const percentReimbursementNoticeEnPlaceholder = `Under the terms of this subsidy, URSSAF requires a percentage reimbursement.`;

const validationSchema = Yup.object({
  parentId: Yup.string(),
  name: Yup.string().required(),
  iconId: Yup.string().when('parentId', {
    is: undefined,
    then: Yup.string().required(),
  }),
  type: Yup.array(Yup.mixed().oneOf(Object.values(SubventionType)).required())
    .required()
    .min(1),
  accessType: Yup.mixed()
    .oneOf(Object.values(SubventionSchemaAccessType))
    .required(),
  spendingModes: Yup.array(
    Yup.mixed().oneOf(Object.values(SubventionSpendingMode)).required(),
  )
    .required()
    .min(1),
  reimbursementPolicy: Yup.string(),
  legalNoticeFr: Yup.string().nullable(),
  legalNoticeEn: Yup.string().nullable(),
  percentReimbursementNoticeFr: Yup.string().nullable(),
  percentReimbursementNoticeEn: Yup.string().nullable(),
}).required();

export const SubventionSchemaCreateForm = (
  props: SubventionSchemaCreateFormProps,
) => {
  const { defaultValues = {}, ...rest } = props;
  const { t } = useTranslation('subvention-schemas');

  const form = useForm<SubventionSchemaCreateFormValues>({
    validationSchema,
    defaultValues: {
      type: [],
      accessType: SubventionSchemaAccessType.Open,
      ...defaultValues,
    },
  });

  const [subventionSchemaMutation] = useSubventionSchemaCreateFormMutation();
  const [loadParent, parentQuery] = useSubventionSchemaCreateParentLazyQuery();

  const formValues = form.getValues();

  useEffect(() => {
    if (formValues.parentId && !parentQuery.data) {
      loadParent({ variables: { subventionSchemaId: formValues.parentId } });
    }
  }, [formValues.parentId]);

  const handleSubmit: FormProps<SubventionSchemaCreateFormValues>['onValid'] =
    async (values) => {
      const variables: SubventionSchemaCreateFormMutationVariables = {
        input: {
          name: values.name,
          iconId: values.iconId,
          parentSubventionSchemaId: values.parentId,
          accessType: values.accessType,
          type: values.type,
          spendingModes: values.spendingModes,
          reimbursementPolicy: values.reimbursementPolicy,
          translations: [],
        },
      };

      if (
        values.legalNoticeFr !== undefined ||
        values.percentReimbursementNoticeFr !== undefined
      ) {
        variables.input.translations.push({
          localeId: 'fr',
          legalNotice: values.legalNoticeFr?.trim() || null,
          percentReimbursementNotice:
            values.percentReimbursementNoticeFr?.trim() || null,
        });
      }

      if (
        values.legalNoticeEn !== undefined ||
        values.percentReimbursementNoticeEn !== undefined
      ) {
        variables.input.translations.push({
          localeId: 'en',
          legalNotice: values.legalNoticeEn?.trim() || null,
          percentReimbursementNotice:
            values.percentReimbursementNoticeEn?.trim() || null,
        });
      }

      try {
        const { data } = await subventionSchemaMutation({ variables });

        return data;
      } catch {
        message.error('Une erreur est survenue');
      }
    };

  return (
    <div>
      <Card>
        <Form
          id="SubventionSchemaCreateForm"
          form={form}
          onValid={handleSubmit}
          className={styles.form}
          {...rest}
        >
          <FormItem
            className={styles.name}
            required
            label={t('fields.name.label')}
            name="name"
          >
            <FormInput name="name" />
          </FormItem>
          <FormItem
            className={styles.accessType}
            required
            label={t('fields.accessType.label')}
            name="accessType"
          >
            <FormSelect
              name="accessType"
              options={[
                {
                  label: t('fields.accessType.values.open'),
                  value: SubventionSchemaAccessType.Open,
                },
                {
                  label: t('fields.accessType.values.whitelist'),
                  value: SubventionSchemaAccessType.Whitelist,
                },
              ]}
            />
          </FormItem>
          <FormItem
            className={styles.spendingModes}
            required
            label={t('fields.spendingModes.label')}
            name="spendingModes"
          >
            <FormSelect
              name="spendingModes"
              multiple
              options={[
                {
                  label: t('fields.spendingModes.values.app'),
                  value: SubventionSpendingMode.App,
                },
                {
                  label: t('fields.spendingModes.values.reimbursement'),
                  value: SubventionSpendingMode.Reimbursement,
                },
              ]}
            />
          </FormItem>
          <FormItem
            className={styles.types}
            required
            label={t('fields.type.label')}
            name="type"
          >
            <FormSelect
              name="type"
              multiple
              options={[
                {
                  label: t('fields.type.values.fund'),
                  value: SubventionType.Fund,
                },
                {
                  label: t('fields.type.values.discount'),
                  value: SubventionType.Discount,
                },
              ]}
            />
          </FormItem>
          <FormItem
            className={styles.reimbursementPolicy}
            label={t('fields.reimbursementPolicy.label')}
            name="reimbursementPolicy"
          >
            <FormTextArea rows={5} name="reimbursementPolicy" />
          </FormItem>

          <FormItem
            className={styles.percentReimbursementNotice}
            label={t('fields.percentReimbursementNotice.fr')}
            name="percentReimbursementNoticeFr"
          >
            <FormTextArea
              rows={4}
              name="percentReimbursementNoticeFr"
              placeholder={percentReimbursementNoticeFrPlaceholder}
            />
          </FormItem>
          <FormItem
            className={styles.percentReimbursementNotice}
            label={t('fields.percentReimbursementNotice.en')}
            name="percentReimbursementNoticeEn"
          >
            <FormTextArea
              rows={4}
              name="percentReimbursementNoticeEn"
              placeholder={percentReimbursementNoticeEnPlaceholder}
            />
          </FormItem>
          <FormItem
            className={styles.legalNotice}
            label={t('fields.legalNotice.fr')}
            name="legalNoticeFr"
          >
            <FormTextArea
              rows={6}
              name="legalNoticeFr"
              placeholder={legalNoticeFrPlaceholder}
              style={{ marginBottom: 10 }}
            />
            <Button
              size="small"
              icon={<UpSquareOutlined />}
              onClick={() => {
                form.setValue('legalNoticeFr', legalNoticeFrPlaceholder);
              }}
            >
              Appliquer le modèle français par défaut
            </Button>
          </FormItem>
          <FormItem
            className={styles.legalNotice}
            label={t('fields.legalNotice.en')}
            name="legalNoticeEn"
          >
            <FormTextArea
              rows={6}
              name="legalNoticeEn"
              placeholder={legalNoticeEnPlaceholder}
              style={{ marginBottom: 10 }}
            />
            <Button
              size="small"
              icon={<UpSquareOutlined />}
              onClick={() => {
                form.setValue('legalNoticeEn', legalNoticeEnPlaceholder);
              }}
            >
              Appliquer le modèle anglais par défaut
            </Button>
          </FormItem>
          {!formValues.parentId && (
            <FormItem
              className={styles.icon}
              required
              label={t('fields.icon.label')}
              name="iconId"
            >
              <FormPicture
                name="iconId"
                previewRatio="1:1"
                type={FileType.SubventionSchemaIcon}
                info="Résolution recommandée 256x256, max 512ko"
              />
            </FormItem>
          )}
          <div className={styles.footer}>
            <Button
              loading={form.formState.isSubmitting}
              type="primary"
              htmlType="submit"
            >
              {t('forms.create.submit')}
            </Button>
          </div>
        </Form>
      </Card>
    </div>
  );
};

export type SubventionSchemaCreateFormProps = ChildFormProps<
  SubventionSchemaCreateFormValues,
  SubventionSchemaCreateFormMutation
> & { defaultValues: Partial<SubventionSchemaCreateFormValues> };
