import { useTranslation } from 'react-i18next';
import { Descriptions } from 'antd';
import classnames from 'classnames';

import { I18nLink } from '@components/atoms/Link/Link';
import { Typography } from '@atoms/Typography/Typography';
import { PageHeader } from '@molecules/PageHeader/PageHeader';

import styles from './OfferHeader.module.css';

import { OfferHeaderFragment } from './OfferHeader.generated';

export const OfferHeader: React.FC<OfferHeaderProps> = (props) => {
  const { className, offer, showNav } = props;
  const { t } = useTranslation('offers');

  return (
    <PageHeader
      showNav={showNav}
      className={classnames(className, styles.root)}
    >
      <Typography.Title
        level={2}
        ellipsis={{ rows: 2 }}
        className={styles.title}
      >
        {offer.name}
      </Typography.Title>
      <Typography.Paragraph copyable>{offer.id}</Typography.Paragraph>

      <Descriptions column={1} size="small">
        <Descriptions.Item label={t('fields.brand')}>
          <I18nLink to={`/brands/list/${offer.brand.id}`}>
            {offer.brand.name}
          </I18nLink>
        </Descriptions.Item>

        <Descriptions.Item label={t('fields.campaign')}>
          <I18nLink to={`/campaigns/list/${offer.campaign?.id}`}>
            {offer.campaign?.name ?? 'N/A'}
          </I18nLink>
        </Descriptions.Item>

        <Descriptions.Item label={t('fields.brandOffers')}>
          <I18nLink to={`/brands/list/${offer.brand.id}/offers`}>
            Toutes les offres de <strong>{offer.brand.name}</strong>
          </I18nLink>
        </Descriptions.Item>

        <Descriptions.Item label={t('fields.availableOnline')}>
          {offer.availableOnline ? 'Oui' : 'Non'}
        </Descriptions.Item>

        <Descriptions.Item label={t('fields.availableCountry')}>
          {offer.availableCountry ? 'Oui' : 'Non'}
        </Descriptions.Item>

        {(offer.__typename === 'OfferGiftCard' ||
          offer.__typename === 'OfferTicket') && (
          <>
            <Descriptions.Item label={t('fields.product')}>
              <I18nLink to={`/products/list/${offer.product.id}`}>
                {offer.product.name}
              </I18nLink>
            </Descriptions.Item>

            <Descriptions.Item label={t('fields.productProvider')}>
              <I18nLink
                to={`/products/providers/list/${offer.product.catalog.provider.id}`}
              >
                {offer.product.catalog.provider.name}
              </I18nLink>
            </Descriptions.Item>

            <Descriptions.Item label={t('fields.productCatalog')}>
              <I18nLink
                to={`/products/catalogs/list/${offer.product.catalog.id}`}
              >
                {offer.product.catalog.name}
              </I18nLink>
            </Descriptions.Item>
          </>
        )}
      </Descriptions>
    </PageHeader>
  );
};

export type OfferHeaderProps = {
  className?: string;
  offer: OfferHeaderFragment;
  showNav?: boolean;
};
