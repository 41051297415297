import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { SearchOutlined } from '@ant-design/icons';
import {
  Button,
  Card,
  Form,
  FormInstance,
  Input,
  Modal,
  Space,
  Table,
  TableProps,
} from 'antd';
import TextArea from 'antd/lib/input/TextArea';
import { chunk } from 'lodash';

import { handleMutationErrors } from '@utils/handleMutationErrors';

import { useSurveyUsersAddMutation } from './SurveyAddUsersModal.generated';

type FormValues = {
  emails: string;
};

interface SurveyAddUsersFormProps {
  initialValues?: Partial<FormValues>;
  onFormInstanceReady: (instance: FormInstance<FormValues>) => void;
}

const SurveyAddUsersForm: React.FC<SurveyAddUsersFormProps> = ({
  initialValues,
  onFormInstanceReady,
}) => {
  const { t } = useTranslation('survey');
  const [form] = Form.useForm<FormValues>();

  useEffect(() => {
    onFormInstanceReady(form);
  }, []);

  return (
    <Form
      layout="vertical"
      form={form}
      name="survey_create_form"
      initialValues={initialValues}
    >
      <Form.Item
        required
        label={t(
          'form.fields.emails',
          'Emails (séparés par des virgules ou 1 par ligne)',
        )}
        name="emails"
      >
        <TextArea />
      </Form.Item>
    </Form>
  );
};

interface SurveyAddUsersModalProps {
  open: boolean;
  onCreate: () => void;
  onCancel: () => void;
  initialValues?: Partial<FormValues>;
  surveyId: string;
}

interface EmailNotAddedType {
  email: string;
}

export const SurveyAddUsersModal: React.FC<SurveyAddUsersModalProps> = ({
  open,
  onCancel,
  onCreate,
  initialValues,
  surveyId,
}) => {
  const [formInstance, setFormInstance] = useState<FormInstance<FormValues>>();
  const [mutation] = useSurveyUsersAddMutation();

  const [emailsNotAdded, setEmailsNotAdded] = useState<EmailNotAddedType[]>([]);

  const handleCreate = async () => {
    if (!formInstance) return;

    setEmailsNotAdded([]);

    try {
      const values = await formInstance.validateFields();

      const emails = values.emails.split(/,|\n/).map((email) => email.trim());
      const chunkEmails = chunk(emails, 1000);
      const result = await Promise.allSettled(
        chunkEmails.map((chunk) =>
          mutation({
            variables: {
              surveyId: surveyId,
              emails: chunk,
            },
          }),
        ),
      );

      const emailsNotFound = result.reduce<EmailNotAddedType[]>(
        (emails, res, index) => {
          if (res.status === 'fulfilled') {
            const { data } = res.value;
            if (data) {
              emails.push(
                ...data.surveyUsersAdd.emailsNotFound.map((email) => ({
                  email,
                })),
              );
            }
          } else {
            emails.push(...chunkEmails[index].map((email) => ({ email })));
          }

          return emails;
        },
        [],
      );

      if (emailsNotFound.length === 0) {
        formInstance?.resetFields();
        onCreate();
      } else {
        setEmailsNotAdded(emailsNotFound);
      }
    } catch (error) {
      handleMutationErrors(error, {});
    }
  };

  const columns: TableProps<EmailNotAddedType>['columns'] = [
    {
      title: 'Emails non ajoutés',
      dataIndex: 'email',
      key: 'email',
      sorter: (a, b) => a.email.localeCompare(b.email),
      sortDirections: ['ascend', 'descend'],
      defaultSortOrder: 'ascend',
      filterDropdown: ({
        setSelectedKeys,
        selectedKeys,
        confirm,
        clearFilters,
      }) => (
        <Card>
          <Space direction="vertical">
            <Input
              placeholder="Recherche Email"
              value={selectedKeys[0]}
              onChange={(e) =>
                setSelectedKeys(e.target.value ? [e.target.value] : [])
              }
              onPressEnter={() => confirm()}
            />
            <Space>
              <Button
                type="primary"
                onClick={() => confirm()}
                icon={<SearchOutlined />}
                size="small"
              >
                Rechercher
              </Button>
              <Button
                onClick={() => {
                  if (clearFilters) {
                    clearFilters();
                  }
                  confirm();
                }}
                size="small"
              >
                Réinitialiser
              </Button>
            </Space>
          </Space>
        </Card>
      ),
      filterIcon: (filtered) => (
        <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />
      ),
      onFilter: (value, record) =>
        record.email
          .toString()
          .toLowerCase()
          .includes((value as string).toLowerCase()),
    },
  ];
  return (
    <Modal
      open={open}
      title="Ajouter des utilisateurs"
      okText="Ajouter"
      cancelText="Annuler"
      okButtonProps={{ autoFocus: true }}
      onCancel={onCancel}
      destroyOnClose
      onOk={handleCreate}
    >
      <SurveyAddUsersForm
        initialValues={initialValues}
        onFormInstanceReady={(instance) => {
          setFormInstance(instance);
        }}
      />
      {emailsNotAdded.length > 0 && (
        <Table
          pagination={{ pageSize: 10 }}
          columns={columns}
          dataSource={emailsNotAdded}
        />
      )}
    </Modal>
  );
};
