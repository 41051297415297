import { useState } from 'react';
import { Avatar, Button, Popconfirm, Space, Typography } from 'antd';
import Checkbox, { CheckboxProps } from 'antd/lib/checkbox/Checkbox';
import classNames from 'classnames';

import { SubventionIdViewDocument } from '../../SubventionIdView.generated';

import { SubventionState } from '@graphql/generated/types';

import { I18nLink } from '@components/atoms/Link/Link';

import styles from './SubventionHeader.module.css';

import {
  SubventionHeaderFragment,
  SubventionNotificationDisableMutation,
  SubventionNotificationEnableMutation,
  useSubventionCatalogComputeMutation,
  useSubventionForceCompleteMutation,
  useSubventionForcePublishMutation,
  useSubventionFreezeMutation,
  useSubventionNotificationDisableMutation,
  useSubventionNotificationEnableMutation,
} from './SubventionHeader.generated';

type SubventionHeaderProps = {
  className?: string;
  subvention: SubventionHeaderFragment;
};

export function SubventionHeader(props: SubventionHeaderProps) {
  const { className, subvention } = props;

  const [shouldNotifyGrantees, setShouldNotifyGrantees] = useState(
    subvention.shouldNotifyGrantees,
  );

  const [forcePublishMutation, forcePublishState] =
    useSubventionForcePublishMutation({
      variables: { subventionId: subvention.id },
      refetchQueries: [
        {
          query: SubventionIdViewDocument,
          variables: { subventionId: subvention.id },
        },
      ],
      awaitRefetchQueries: true,
    });

  const [forceCompleteMutation, forceCompleteState] =
    useSubventionForceCompleteMutation({
      variables: { subventionId: subvention.id },
      refetchQueries: [
        {
          query: SubventionIdViewDocument,
          variables: { subventionId: subvention.id },
        },
      ],
      awaitRefetchQueries: true,
    });

  const [computeSubventionCatalog, computeSubventionCatalogState] =
    useSubventionCatalogComputeMutation({
      variables: { subventionId: subvention.id },
    });

  const [freezeMutation, freezeState] = useSubventionFreezeMutation({
    variables: { subventionId: subvention.id },
    refetchQueries: [
      {
        query: SubventionIdViewDocument,
        variables: { subventionId: subvention.id },
      },
    ],
    awaitRefetchQueries: true,
  });

  const [subventionNotificationEnable] =
    useSubventionNotificationEnableMutation({
      variables: { subventionId: subvention.id },
      onCompleted: ({
        subventionNotificationEnable: {
          subvention: { shouldNotifyGrantees },
        },
      }: SubventionNotificationEnableMutation) => {
        setShouldNotifyGrantees(shouldNotifyGrantees);
      },
    });

  const [subventionNotificationDisable] =
    useSubventionNotificationDisableMutation({
      variables: { subventionId: subvention.id },
      onCompleted: ({
        subventionNotificationDisable: {
          subvention: { shouldNotifyGrantees },
        },
      }: SubventionNotificationDisableMutation) => {
        setShouldNotifyGrantees(shouldNotifyGrantees);
      },
    });

  const onChangeShouldNotifyGrantees: CheckboxProps['onChange'] = async (e) => {
    if (e.target.checked) {
      await subventionNotificationEnable();
    } else {
      await subventionNotificationDisable();
    }
  };

  return (
    <div className={classNames(styles.root, className)}>
      <div className={styles.icon}>
        <Avatar
          shape="square"
          size={64}
          src={subvention.subventionSchema?.icon?.file.url}
        >
          Icon
        </Avatar>
      </div>
      <div className={styles.texts}>
        <Typography.Title level={3}>{subvention.name}</Typography.Title>
        {subvention.company && (
          <Typography.Paragraph>
            de&nbsp;
            <I18nLink to={`/companies/list/${subvention.company.id}`}>
              {subvention.company.name}
            </I18nLink>
          </Typography.Paragraph>
        )}
      </div>
      <Space size="middle" className={styles.buttons}>
        {[
          SubventionState.Draft,
          SubventionState.Confirmed,
          SubventionState.Ready,
        ].includes(subvention.state) && (
          <Checkbox
            checked={shouldNotifyGrantees}
            onChange={onChangeShouldNotifyGrantees}
          >
            Envoie des notifications
          </Checkbox>
        )}
        <Popconfirm
          okText="Rafraîchir"
          onConfirm={() => computeSubventionCatalog()}
          title="Cette action va rafraîchir le catalogue"
        >
          <Button loading={computeSubventionCatalogState.loading}>
            Rafraîchir catalogue
          </Button>
        </Popconfirm>
        {!subvention.granteesFrozen && (
          <Popconfirm
            okText="Figer"
            onConfirm={() => freezeMutation()}
            title="Cette action va figer la subvention instantanément"
          >
            <Button loading={freezeState.loading}>Figer la publication</Button>
          </Popconfirm>
        )}
        {subvention.state === SubventionState.Ready && (
          <Popconfirm
            okText="Publier"
            onConfirm={() => forcePublishMutation()}
            title="Cette action va publier la subvention instantanément"
          >
            <Button loading={forcePublishState.loading}>
              Forcer la publication
            </Button>
          </Popconfirm>
        )}

        {subvention.state === SubventionState.Published && (
          <Popconfirm
            okText="Terminer"
            onConfirm={() => forceCompleteMutation()}
            title="Cette action va terminer la subvention instantanément"
          >
            <Button loading={forceCompleteState.loading}>
              Forcer la clôture
            </Button>
          </Popconfirm>
        )}
      </Space>
    </div>
  );
}
