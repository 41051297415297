import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { RouteComponentProps } from 'react-router';
import {
  Breadcrumb,
  Button,
  Layout,
  Popconfirm,
  Space,
  Spin,
  Tabs,
} from 'antd';

import { CohortSelectionMode, SubventionState } from '@graphql/generated/types';

import { I18nLink } from '@components/atoms/Link/Link';
import { SubventionGranteeAddModal } from '@components/modals/SubventionGranteeAddModal/SubventionGranteeAddModal';
import { AuditTrailList } from '@components/organisms/AuditTrailList/AuditTrailList';

import styles from './SubventionIdView.module.css';

import { SubventionBeneficiaries } from './components/SubventionBeneficiaries/SubventionBeneficiaries';
import { SubventionConsumptionCard } from './components/SubventionConsumptionCard/SubventionConsumptionCard';
import { SubventionHeader } from './components/SubventionHeader/SubventionHeader';
import { SubventionInformationCard } from './components/SubventionInformationCard/SubventionInformationCard';
import { SubventionInformationsModal } from './components/SubventionInformationsModal/SubventionInformationsModal';
import { SubventionSteps } from './components/SubventionSteps/SubventionSteps';
import { SubventionTimelineCard } from './components/SubventionTimelineCard/SubventionTimelineCard';
import { SubventionTransactions } from './components/SubventionTransactions/SubventionTransactions';
import {
  useSubventionGranteeComputeMutation,
  useSubventionIdViewQuery,
} from './SubventionIdView.generated';

export type SubventionIdViewProps = RouteComponentProps<{
  subventionId: string;
}> & {
  className?: string;
};
export const SubventionIdView = ({ match }: SubventionIdViewProps) => {
  const {
    params: { subventionId },
  } = match;

  const { t } = useTranslation('subventions');
  const [showExtraButton, setShowExtraButton] = useState(true);

  const query = useSubventionIdViewQuery({
    variables: {
      subventionId,
    },
  });

  const [computeSubventionGrantee, computeSubventionGranteeState] =
    useSubventionGranteeComputeMutation({
      variables: { subventionId },
      refetchQueries: ['SubventionBeneficiaries'],
    });

  const data = query.data;

  const [isDrawerVisible, setIsDrawerVisible] = useState(false);
  const [isSubventionGranteeAddVisible, setIsSubventionGranteeAddVisible] =
    useState(false);

  const subvention = data?.subvention;

  if (!subvention) {
    return <Spin size="large" />;
  }

  const { state, cohort } = subvention;

  const isSubventionInPublishedOrReadyState = [
    SubventionState.Published,
    SubventionState.Ready,
  ].includes(state);

  const isSubventionInManualModeOrFrozenMode =
    !!cohort &&
    (cohort.metadata.selectionMode === CohortSelectionMode.Manual ||
      subvention.granteesFrozen);

  const canAddBeneficiary =
    isSubventionInPublishedOrReadyState && isSubventionInManualModeOrFrozenMode;

  const onChangeTab = (key: string) => {
    if (key === 'beneficiaries') {
      setShowExtraButton(true);
    } else {
      setShowExtraButton(false);
    }
  };

  return (
    <Layout className={styles.root}>
      <Breadcrumb>
        <Breadcrumb.Item>
          <I18nLink to="/subventions/subventions">Subventions</I18nLink>
        </Breadcrumb.Item>
        <Breadcrumb.Item>{subvention?.name}</Breadcrumb.Item>
      </Breadcrumb>
      <SubventionHeader className={styles.header} subvention={subvention} />
      <SubventionSteps className={styles.steps} subvention={subvention} />
      <div className={styles.cards}>
        <SubventionConsumptionCard
          className={styles.card}
          subvention={subvention}
        />
        <SubventionTimelineCard
          className={styles.card}
          subvention={subvention}
          query={query}
        />
        <SubventionInformationCard
          className={styles.card}
          subvention={subvention}
          onDetailClick={() => setIsDrawerVisible(true)}
        />
      </div>
      <Tabs
        className={styles.tabs}
        onChange={onChangeTab}
        tabBarExtraContent={
          <Space>
            {showExtraButton && canAddBeneficiary ? (
              <Button
                onClick={() => {
                  setIsSubventionGranteeAddVisible(true);
                }}
              >
                {t('buttons.grantee.add', 'Ajouter un bénéficiaire')}
              </Button>
            ) : null}
            {!subvention.granteesFrozen && (
              <Popconfirm
                okText="Mettre à jour"
                onConfirm={() => computeSubventionGrantee()}
                title="Cette action va mettre à jour les bénéféciaires"
              >
                <Button loading={computeSubventionGranteeState.loading}>
                  Mettre à jour
                </Button>
              </Popconfirm>
            )}
          </Space>
        }
      >
        <Tabs.TabPane tab="Bénéficiaires" key="beneficiaries">
          <SubventionBeneficiaries subventionId={subvention.id} />
        </Tabs.TabPane>
        <Tabs.TabPane tab="Transactions" key="transactions">
          <SubventionTransactions subventionId={subvention.id} />
        </Tabs.TabPane>
      </Tabs>
      <AuditTrailList
        className={styles.auditTrail}
        auditTrailPagination={subvention.auditTrailPagination}
      />
      <SubventionInformationsModal
        opened={isDrawerVisible}
        onClose={() => setIsDrawerVisible(false)}
        subvention={subvention}
      />
      <SubventionGranteeAddModal
        subventionId={subvention.id}
        companyId={subvention.company?.id}
        isOpen={isSubventionGranteeAddVisible}
        onClose={() => setIsSubventionGranteeAddVisible(false)}
      />
    </Layout>
  );
};
