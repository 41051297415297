import { useTranslation } from 'react-i18next';
import { useLocation, useRouteMatch } from 'react-router';
import { Link } from 'react-router-dom';
import { Layout, Menu } from 'antd';
import classnames from 'classnames';

import { AppLogo } from '@atoms/AppLogo/AppLogo';

import styles from './AppSideMenu.module.css';

const { Sider } = Layout;

export const AppSideMenu: React.FC<AppSideMenu> = (props) => {
  const { className } = props;

  const location = useLocation();
  const match = useRouteMatch();
  const { t } = useTranslation();

  const selectedKeys: string[] = [];

  location.pathname
    .split('/')
    .slice(1)
    .map((location) => {
      selectedKeys.push(
        [selectedKeys[selectedKeys.length - 1], location].join('/'),
      );
    });

  return (
    <Sider width={240} className={classnames(styles.root, className)}>
      <AppLogo className={styles.logo} />
      <Menu
        theme="dark"
        selectedKeys={selectedKeys}
        defaultOpenKeys={selectedKeys}
        mode="inline"
      >
        {/* <Menu.Item key={`${match.url}/dashboard`}>
          <Link to={`${match.url}/dashboard`}>{t('sideMenu.dashboard')}</Link>
        </Menu.Item> */}

        <Menu.SubMenu key={`${match.url}/users`} title={t('sideMenu.users')}>
          <Menu.Item key={`${match.url}/users/list`}>
            <Link to={`${match.url}/users/list`}>
              {t('sideMenu.usersList')}
            </Link>
          </Menu.Item>
          <Menu.Item key={`${match.url}/users/create`}>
            <Link to={`${match.url}/users/create`}>
              {t('sideMenu.usersCreate')}
            </Link>
          </Menu.Item>
        </Menu.SubMenu>

        <Menu.SubMenu
          key={`${match.url}/companies`}
          title={t('sideMenu.companies')}
        >
          <Menu.Item key={`${match.url}/companies/list`}>
            <Link to={`${match.url}/companies/list`}>
              {t('sideMenu.companiesList')}
            </Link>
          </Menu.Item>
          <Menu.Item key={`${match.url}/companies/create`}>
            <Link to={`${match.url}/companies/create`}>
              {t('sideMenu.companiesCreate')}
            </Link>
          </Menu.Item>
        </Menu.SubMenu>
        <Menu.SubMenu
          key={`${match.url}/subventions`}
          title={t('sideMenu.subventions.block')}
        >
          <Menu.Item key={`${match.url}/subventions/subventions`}>
            <Link to={`${match.url}/subventions/subventions`}>
              {t('sideMenu.subventions.subventions')}
            </Link>
          </Menu.Item>
          <Menu.Item key={`${match.url}/subventions/schemas`}>
            <Link to={`${match.url}/subventions/schemas`}>
              {t('sideMenu.subventions.schemas')}
            </Link>
          </Menu.Item>
          <Menu.Item key={`${match.url}/subventions/reimbursement`}>
            <Link to={`${match.url}/subventions/reimbursement`}>
              {t('sideMenu.subventions.reimbursement')}
            </Link>
          </Menu.Item>
        </Menu.SubMenu>

        {/* MANGOPAY */}
        <Menu.SubMenu key={`${match.url}/mangopay`} title="Mangopay">
          <Menu.Item key={`${match.url}/mangopay/kyc`}>
            <Link to={`${match.url}/mangopay/kyc`}>
              {t('sideMenu.mangopay.kyc', 'KYC')}
            </Link>
          </Menu.Item>
          <Menu.Item key={`${match.url}/mangopay/payout`}>
            <Link to={`${match.url}/mangopay/payout`}>
              {t('sideMenu.mangopay.payout', 'PayOuts')}
            </Link>
          </Menu.Item>
          <Menu.Item key={`${match.url}/mangopay/transfer`}>
            <Link to={`${match.url}/mangopay/transfer`}>
              {t('sideMenu.mangopay.transfer', 'Transfers')}
            </Link>
          </Menu.Item>
        </Menu.SubMenu>

        <Menu.SubMenu key={`${match.url}/brands`} title={t('sideMenu.brands')}>
          <Menu.Item key={`${match.url}/brands/list`}>
            <Link to={`${match.url}/brands/list`}>
              {t('sideMenu.brandsList')}
            </Link>
          </Menu.Item>
          <Menu.Item key={`${match.url}/brands/create`}>
            <Link to={`${match.url}/brands/create`}>
              {t('sideMenu.brandsCreate')}
            </Link>
          </Menu.Item>
        </Menu.SubMenu>

        <Menu.SubMenu
          key={`${match.url}/campaign-selections`}
          title={t('sideMenu.campaignSelections')}
        >
          <Menu.Item key={`${match.url}/campaign-selections/list`}>
            <Link to={`${match.url}/campaign-selections/list`}>
              {t('sideMenu.campaignSelectionsList')}
            </Link>
          </Menu.Item>
          <Menu.Item key={`${match.url}/campaign-selections/create`}>
            <Link to={`${match.url}/campaign-selections/create`}>
              {t('sideMenu.campaignSelectionsCreate')}
            </Link>
          </Menu.Item>
        </Menu.SubMenu>

        <Menu.SubMenu
          key={`${match.url}/campaigns`}
          title={t('sideMenu.campaigns')}
        >
          <Menu.Item key={`${match.url}/campaigns/list`}>
            <Link to={`${match.url}/campaigns/list`}>
              {t('sideMenu.campaignsList')}
            </Link>
          </Menu.Item>
          <Menu.Item key={`${match.url}/campaigns/create`}>
            <Link to={`${match.url}/campaigns/create`}>
              {t('sideMenu.campaignsCreate')}
            </Link>
          </Menu.Item>
        </Menu.SubMenu>

        <Menu.SubMenu key={`${match.url}/offers`} title={t('sideMenu.offers')}>
          <Menu.Item key={`${match.url}/offers/list`}>
            <Link to={`${match.url}/offers/list`}>
              {t('sideMenu.offersList')}
            </Link>
          </Menu.Item>
          <Menu.Item key={`${match.url}/offers/create`}>
            <Link to={`${match.url}/offers/create`}>
              {t('sideMenu.offersCreate')}
            </Link>
          </Menu.Item>
        </Menu.SubMenu>

        <Menu.SubMenu
          key={`${match.url}/categories`}
          title={t('sideMenu.categories')}
        >
          <Menu.Item key={`${match.url}/categories/list`}>
            <Link to={`${match.url}/categories/list`}>
              {t('sideMenu.categoriesList')}
            </Link>
          </Menu.Item>
          <Menu.Item key={`${match.url}/categories/create`}>
            <Link to={`${match.url}/categories/create`}>
              {t('sideMenu.categoriesCreate')}
            </Link>
          </Menu.Item>
        </Menu.SubMenu>

        <Menu.SubMenu
          key={`${match.url}/products`}
          title={t('sideMenu.products')}
        >
          <Menu.Item key={`${match.url}/products/list`}>
            <Link to={`${match.url}/products/list`}>
              {t('sideMenu.productsList')}
            </Link>
          </Menu.Item>
          <Menu.Item key={`${match.url}/products/create`}>
            <Link to={`${match.url}/products/create`}>
              {t('sideMenu.productCreate')}
            </Link>
          </Menu.Item>
          <Menu.Item key={`${match.url}/product-variants/list`}>
            <Link to={`${match.url}/product-variants/list`}>
              {t('sideMenu.productVariantsList')}
            </Link>
          </Menu.Item>
          <Menu.Item key={`${match.url}/product-articles/list`}>
            <Link to={`${match.url}/product-articles/list`}>
              {t('sideMenu.productArticlesList')}
            </Link>
          </Menu.Item>
          <Menu.Item key={`${match.url}/product-articles/create`}>
            <Link to={`${match.url}/product-articles/create`}>
              {t('sideMenu.productArticleCreate')}
            </Link>
          </Menu.Item>
          <Menu.Item key={`${match.url}/products/providers`}>
            <Link to={`${match.url}/products/providers`}>
              {t('sideMenu.productProviders')}
            </Link>
          </Menu.Item>
          <Menu.Item key={`${match.url}/products/catalogs/list`}>
            <Link to={`${match.url}/products/catalogs/list`}>
              {t('sideMenu.productCatalogs')}
            </Link>
          </Menu.Item>
          <Menu.Item key={`${match.url}/products/catalogs/create`}>
            <Link to={`${match.url}/products/catalogs/create`}>
              {t('sideMenu.productCatalogCreate')}
            </Link>
          </Menu.Item>
          <Menu.Item key={`${match.url}/products/synchronizations`}>
            <Link to={`${match.url}/products/synchronizations`}>
              {t('sideMenu.productSynchronizations')}
            </Link>
          </Menu.Item>
        </Menu.SubMenu>

        <Menu.SubMenu
          key={`${match.url}/tax-rates`}
          title={t('sideMenu.taxRates')}
        >
          <Menu.Item key={`${match.url}/tax-rates/list`}>
            <Link to={`${match.url}/tax-rates/list`}>
              {t('sideMenu.taxRatesList')}
            </Link>
          </Menu.Item>
        </Menu.SubMenu>

        <Menu.SubMenu key={`${match.url}/search`} title={t('sideMenu.search')}>
          <Menu.Item key={`${match.url}/search/synchronizations`}>
            <Link to={`${match.url}/search/synchronizations`}>
              {t('sideMenu.searchSynchronizations')}
            </Link>
          </Menu.Item>
        </Menu.SubMenu>

        <Menu.SubMenu
          key={`${match.url}/orders`}
          title={t('sideMenu.orders.block', 'Commandes')}
        >
          <Menu.Item key={`${match.url}/orders/list`}>
            <Link to={`${match.url}/orders/list`}>
              {t('sideMenu.orders.list', 'Liste des commandes')}
            </Link>
          </Menu.Item>
          <Menu.Item key={`${match.url}/orders/refund/list`}>
            <Link to={`${match.url}/orders/refund/list`}>
              {t('sideMenu.orders.refund', 'Remboursements de commande')}
            </Link>
          </Menu.Item>
        </Menu.SubMenu>

        <Menu.Item key={`${match.url}/payments`}>
          <Link to={`${match.url}/payments`}>{t('sideMenu.payments')}</Link>
        </Menu.Item>

        {/* COMMUNICATION */}
        <Menu.SubMenu
          key={`${match.url}/communication`}
          title={t('sideMenu.communication.title')}
        >
          <Menu.Item key={`${match.url}/communication/articles`}>
            <Link to={`${match.url}/communication/articles`}>
              {t('sideMenu.communication.articles')}
            </Link>
          </Menu.Item>
          <Menu.Item key={`${match.url}/communication/publications`}>
            <Link to={`${match.url}/communication/publications`}>
              {t('sideMenu.communication.publications')}
            </Link>
          </Menu.Item>
          <Menu.Item key={`${match.url}/communication/highlights`}>
            <Link to={`${match.url}/communication/highlights`}>
              {t('sideMenu.communication.highlights')}
            </Link>
          </Menu.Item>
        </Menu.SubMenu>

        <Menu.SubMenu
          key={`${match.url}/happenings`}
          title={t('sideMenu.happenings')}
        >
          <Menu.Item key={`${match.url}/happenings/list`}>
            <Link to={`${match.url}/happenings/list`}>
              {t('sideMenu.happeningsList')}
            </Link>
          </Menu.Item>
          <Menu.Item key={`${match.url}/happenings/create`}>
            <Link to={`${match.url}/happenings/create`}>
              {t('sideMenu.happeningsCreate')}
            </Link>
          </Menu.Item>
        </Menu.SubMenu>

        <Menu.SubMenu key={`${match.url}/games`} title={t('sideMenu.games')}>
          <Menu.Item key={`${match.url}/games/list`}>
            <Link to={`${match.url}/games/list`}>
              {t('sideMenu.gamesList')}
            </Link>
          </Menu.Item>
          <Menu.Item key={`${match.url}/games/create`}>
            <Link to={`${match.url}/games/create`}>
              {t('sideMenu.gamesCreate')}
            </Link>
          </Menu.Item>
        </Menu.SubMenu>

        <Menu.SubMenu
          key={`${match.url}/parameters`}
          title={t('sideMenu.parameters')}
        >
          <Menu.Item key={`${match.url}/parameters/picture-stock/list`}>
            <Link to={`${match.url}/parameters/picture-stock/list`}>
              {t('sideMenu.listPicturesStock')}
            </Link>
          </Menu.Item>
          <Menu.Item key={`${match.url}/parameters/discover`}>
            <Link to={`${match.url}/parameters/discover`}>
              {t('sideMenu.discover')}
            </Link>
          </Menu.Item>
        </Menu.SubMenu>

        <Menu.SubMenu
          key={`${match.url}/tools`}
          title={t('sideMenu.tools.title')}
        >
          <Menu.Item key={`${match.url}/tools/aws-waf-ipset`}>
            <Link to={`${match.url}/tools/aws-waf-ipset`}>
              {t('sideMenu.tools.aws-waf-ipset')}
            </Link>
          </Menu.Item>
          <Menu.Item key={`${match.url}/tools/translations`}>
            <Link to={`${match.url}/tools/translations`}>
              {t('sideMenu.tools.translations')}
            </Link>
          </Menu.Item>
          <Menu.Item key={`${match.url}/tools/query-builder`}>
            <Link to={`${match.url}/tools/query-builder`}>
              {t('sideMenu.tools.query-builder')}
            </Link>
          </Menu.Item>
          <Menu.Item key={`${match.url}/tools/reduc-ce-invoices`}>
            <Link to={`${match.url}/tools/reduc-ce-invoices`}>
              {t('sideMenu.tools.reduc-ce-invoices')}
            </Link>
          </Menu.Item>
          <Menu.Item key={`${match.url}/tools/reduc-ce-order-state`}>
            <Link to={`${match.url}/tools/reduc-ce-order-state`}>
              ReducCE Order
            </Link>
          </Menu.Item>
          <Menu.Item key={`${match.url}/tools/ifg-order-state`}>
            <Link to={`${match.url}/tools/ifg-order-state`}>IFG Order</Link>
          </Menu.Item>
          <Menu.Item key={`${match.url}/tools/appdrag-company-migrations`}>
            <Link to={`${match.url}/tools/appdrag-company-migrations`}>
              {t('sideMenu.tools.appdrag-company-migration')}
            </Link>
          </Menu.Item>
          <Menu.Item key={`${match.url}/tools/sorewards-insert`}>
            <Link to={`${match.url}/tools/sorewards-insert`}>
              {t('sideMenu.tools.sorewards-insert')}
            </Link>
          </Menu.Item>
        </Menu.SubMenu>

        <Menu.SubMenu
          key={`${match.url}/redbin`}
          title={t('sideMenu.redbin.title')}
        >
          <Menu.Item key={`${match.url}/redbin/offers-ending-soon`}>
            <Link to={`${match.url}/redbin/offers-ending-soon`}>
              {t('sideMenu.redbin.offers-ending-soon')}
            </Link>
          </Menu.Item>
          <Menu.Item key={`${match.url}/redbin/offers-ended`}>
            <Link to={`${match.url}/redbin/offers-ended`}>
              {t('sideMenu.redbin.offers-ended')}
            </Link>
          </Menu.Item>
          <Menu.Item key={`${match.url}/redbin/offers-without-category`}>
            <Link to={`${match.url}/redbin/offers-without-category`}>
              {t('sideMenu.redbin.offers-without-category')}
            </Link>
          </Menu.Item>
          <Menu.Item key={`${match.url}/redbin/offers-without-store`}>
            <Link to={`${match.url}/redbin/offers-without-store`}>
              {t('sideMenu.redbin.offers-without-store')}
            </Link>
          </Menu.Item>
          <Menu.Item key={`${match.url}/redbin/offers-low-coupons`}>
            <Link to={`${match.url}/redbin/offers-low-coupons`}>
              {t('sideMenu.redbin.offers-low-coupons')}
            </Link>
          </Menu.Item>
          <Menu.Item key={`${match.url}/redbin/offers-without-discount`}>
            <Link to={`${match.url}/redbin/offers-without-discount`}>
              {t('sideMenu.redbin.offers-without-discount')}
            </Link>
          </Menu.Item>
          <Menu.Item key={`${match.url}/redbin/offers-without-url`}>
            <Link to={`${match.url}/redbin/offers-without-url`}>
              {t('sideMenu.redbin.offers-without-url')}
            </Link>
          </Menu.Item>
          <Menu.Item
            key={`${match.url}/redbin/offers-with-products-expiring-soon`}
          >
            <Link to={`${match.url}/redbin/offers-with-products-expiring-soon`}>
              {t('sideMenu.redbin.offers-with-products-expiring-soon')}
            </Link>
          </Menu.Item>
          <Menu.Item key={`${match.url}/redbin/offers-missing-translation`}>
            <Link to={`${match.url}/redbin/offers-missing-translation`}>
              {t('sideMenu.redbin.offers-missing-translation')}
            </Link>
          </Menu.Item>
          <Menu.Item key={`${match.url}/redbin/products-expiring-soon`}>
            <Link to={`${match.url}/redbin/products-expiring-soon`}>
              {t('sideMenu.redbin.products-expiring-soon')}
            </Link>
          </Menu.Item>
          <Menu.Item key={`${match.url}/redbin/products-little-stock`}>
            <Link to={`${match.url}/redbin/products-little-stock`}>
              {t('sideMenu.redbin.products-little-stock')}
            </Link>
          </Menu.Item>
          <Menu.Item key={`${match.url}/redbin/campaigns-without-cover`}>
            <Link to={`${match.url}/redbin/campaigns-without-cover`}>
              {t('sideMenu.redbin.campaigns-without-cover')}
            </Link>
          </Menu.Item>
          <Menu.Item key={`${match.url}/redbin/campaigns-without-thumbnail`}>
            <Link to={`${match.url}/redbin/campaigns-without-thumbnail`}>
              {t('sideMenu.redbin.campaigns-without-thumbnail')}
            </Link>
          </Menu.Item>
          <Menu.Item key={`${match.url}/redbin/campaigns-missing-translation`}>
            <Link to={`${match.url}/redbin/campaigns-missing-translation`}>
              {t('sideMenu.redbin.campaigns-missing-translation')}
            </Link>
          </Menu.Item>
          <Menu.Item key={`${match.url}/redbin/brands-without-logo`}>
            <Link to={`${match.url}/redbin/brands-without-logo`}>
              {t('sideMenu.redbin.brands-without-logo')}
            </Link>
          </Menu.Item>
          <Menu.Item key={`${match.url}/redbin/brands-missing-translation`}>
            <Link to={`${match.url}/redbin/brands-missing-translation`}>
              {t('sideMenu.redbin.brands-missing-translation')}
            </Link>
          </Menu.Item>
          <Menu.Item key={`${match.url}/redbin/orders-not-delivered`}>
            <Link to={`${match.url}/redbin/orders-not-delivered`}>
              {t('sideMenu.redbin.orders-not-delivered')}
            </Link>
          </Menu.Item>
          <Menu.Item key={`${match.url}/redbin/stores-without-province`}>
            <Link to={`${match.url}/redbin/stores-without-province`}>
              {t('sideMenu.redbin.stores-without-province')}
            </Link>
          </Menu.Item>
        </Menu.SubMenu>
      </Menu>
    </Sider>
  );
};

export type AppSideMenu = {
  className?: string;
};
