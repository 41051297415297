import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { CheckOutlined, CloseOutlined } from '@ant-design/icons';
import { Button, Card, Divider, Space, Switch, Typography } from 'antd';
import * as Yup from 'yup';

import { FormInput } from '@components/atoms/FormInput/FormInput';
import { FormInputNumber } from '@components/atoms/FormInputNumber/FormInputNumber';
import { FormItem } from '@components/atoms/FormItem/FormItem';
import { Form, FormProps, useForm } from '@components/organisms/Form/Form';

import {
  useCompanyFeatureConfigHappyMediaViewQuery,
  useMutationCompanyFeatureConfigHappyMediaViewDisableMutation,
  useMutationCompanyFeatureConfigHappyMediaViewEnableMutation,
  useMutationCompanyFeatureConfigHappyMediaViewMutation,
} from './CompanyFeatureConfigHappyMediaView.generated';

interface CompanyFeatureConfigHappyMediaViewProps {
  companyId: string;
}

type HappyMediaConfigFormValues = {
  bmId: number;
  bmKey: string;
};

export const CompanyFeatureConfigHappyMediaView = (
  props: CompanyFeatureConfigHappyMediaViewProps,
) => {
  const { companyId } = props;
  const { t } = useTranslation('companies');

  const { data, loading: isLoadingQuery } =
    useCompanyFeatureConfigHappyMediaViewQuery({
      variables: { companyId },
    });
  const config = data?.company?.featureConfig.happyMedia;

  useEffect(() => {
    if (!config) {
      return;
    }

    form.setValue('bmId', config.bmId || 0);
    form.setValue('bmKey', config.bmKey || '');
  }, [config]);

  const validationSchema = Yup.object({
    bmId: Yup.number().required(),
    bmKey: Yup.string().required(),
  }).required();
  const form = useForm<HappyMediaConfigFormValues>({
    validationSchema,
    defaultValues: {
      bmId: config?.bmId || 0,
      bmKey: config?.bmKey || '',
    },
  });

  const [updateMutation, updateMutationState] =
    useMutationCompanyFeatureConfigHappyMediaViewMutation({});
  const [enableMutation, enableMutationState] =
    useMutationCompanyFeatureConfigHappyMediaViewEnableMutation({});
  const [disableMutation, disableMutationState] =
    useMutationCompanyFeatureConfigHappyMediaViewDisableMutation({});

  const handleSubmit: FormProps<HappyMediaConfigFormValues>['onValid'] = async (
    values,
  ) => {
    await updateMutation({
      variables: {
        companyId,
        input: {
          bmId: values.bmId,
          bmKey: values.bmKey,
        },
      },
    });
  };

  const handleFeatureToggle = async (checked: boolean) => {
    console.log('handleFeatureToggle', checked);
    if (checked) {
      await enableMutation({ variables: { companyId } });
    } else {
      console.log('disableMutation', companyId);
      await disableMutation({ variables: { companyId } });
    }
  };

  const isLoading =
    isLoadingQuery ||
    updateMutationState.loading ||
    enableMutationState.loading ||
    disableMutationState.loading;

  return (
    <div>
      <Space>
        <Typography>{t('companyFeatureConfig.switch.label')}</Typography>
        <Switch
          checkedChildren={<CheckOutlined />}
          unCheckedChildren={<CloseOutlined />}
          disabled={isLoading}
          checked={config?.isEnabled}
          onChange={handleFeatureToggle}
          loading={isLoading}
        />
      </Space>
      <Divider />
      {config?.isEnabled && (
        <Card>
          <Space direction="vertical">
            <Form
              id="CompanyFeatureConfigHappyMediaView"
              form={form}
              onValid={handleSubmit}
            >
              <FormItem name="bmId" required label="bmId">
                <FormInputNumber name="bmId" />
              </FormItem>
              <FormItem name="bmKey" required label="bmKey">
                <FormInput name="bmKey" />
              </FormItem>
              <Button
                loading={form.formState.isSubmitting}
                type="primary"
                htmlType="submit"
              >
                {t('common:action.save')}
              </Button>
            </Form>
          </Space>
        </Card>
      )}
    </div>
  );
};
