import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { Button } from 'antd';
import classNames from 'classnames';

import {
  CampaignDeleteButton,
  CampaignDeleteButtonProps,
} from '../CampaignDeleteButton/CampaignDeleteButton';

import { FileType } from '@graphql/generated/types';

import {
  COVER_ASPECT_RATIO,
  COVER_RESOLUTION_INFO,
  COVER_SIZE_INFO,
} from '@utils/constants';

import { I18nLink } from '@components/atoms/Link/Link';
import { Typography } from '@atoms/Typography/Typography';
import { PageHeader } from '@molecules/PageHeader/PageHeader';

import styles from './CampaignHeader.module.css';

import {
  CampaignHeaderFragment,
  useCampaignHeaderUpdateMutation,
} from './CampaignHeader.generated';

export const CampaignHeader: React.FC<CampaignHeaderProps> = (props) => {
  const { className, campaign, showNav } = props;

  const { t, i18n } = useTranslation(['campaigns', 'common']);
  const history = useHistory();

  const [mutation] = useCampaignHeaderUpdateMutation();

  const handleUpdate = async (
    property: 'coverId' | 'thumbnailId',
    pictureId: string,
  ) => {
    await mutation({
      variables: {
        campaignId: campaign.id,
        input: {
          [property]: pictureId,
        },
      },
    });
  };

  const onDeleteSuccess: CampaignDeleteButtonProps['onSuccess'] = (_data) => {
    history.push(
      `/${i18n.language}/brands/list/${campaign.brand.id}/campaigns`,
    );
  };

  const previewUrl = `${process.env.REACT_APP_HAPPYPAL_URL}/superadmin/preview/${campaign.id}`;

  return (
    <PageHeader
      cover={campaign.cover}
      logo={campaign.thumbnail}
      showNav={showNav}
      className={classNames(className, styles.root)}
      uploadLogoProps={{
        fileType: FileType.CampaignThumbnail,
        onUploadPicture: (pictureId) => handleUpdate('thumbnailId', pictureId),
        title: 'Thumbnail',
        picture: campaign.thumbnail,
        info: t('picture.advice', {
          resolution: '1140x640',
          size: 5,
          ns: 'common',
        }),
        aspect: 1,
      }}
      uploadCoverProps={{
        fileType: FileType.CampaignCover,
        onUploadPicture: (pictureId) => handleUpdate('coverId', pictureId),
        title: 'Cover',
        picture: campaign.cover,
        info: t('picture.advice', {
          resolution: COVER_RESOLUTION_INFO,
          size: COVER_SIZE_INFO,
          ns: 'common',
        }),
        aspect: COVER_ASPECT_RATIO,
        crop: true,
      }}
    >
      <Typography.Title
        level={2}
        ellipsis={{ rows: 2 }}
        className={styles.title}
      >
        {campaign.name}
      </Typography.Title>
      <Typography.Paragraph copyable>{campaign.id}</Typography.Paragraph>
      <Typography.Paragraph>
        <strong className={styles.brandLabel}>
          {t('campaignHeader.brand')}
        </strong>
        <I18nLink to={`/brands/list/${campaign.brand.id}`}>
          {campaign.brand.name}
        </I18nLink>
      </Typography.Paragraph>

      <Typography.Paragraph ellipsis className={styles.categories}>
        {campaign.categoriesPagination.nodes.map((n) => n.name).join(', ')}
      </Typography.Paragraph>

      <a
        href={previewUrl}
        onClick={(e) => {
          e.stopPropagation();
          window.open(previewUrl, 'Popup', 'width=1400,height=600');
        }}
        target="_blank"
        rel="noreferrer"
      >
        <Button>Aperçu</Button>
      </a>

      {!campaign.offersPagination.totalCount && (
        <CampaignDeleteButton
          campaign={campaign}
          mode="iconAndText"
          onSuccess={onDeleteSuccess}
        />
      )}
    </PageHeader>
  );
};

export type CampaignHeaderProps = {
  className?: string;
  campaign: CampaignHeaderFragment;
  showNav?: boolean;
};
