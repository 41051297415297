import { Button, Spin } from 'antd';
import classnames from 'classnames';
import qs from 'qs';

import { NotFoundView } from '@components/views/Errors/NotFoundView/NotFoundView';
import { I18nLink } from '@atoms/Link/Link';
import { OffersTable } from '@tables/OffersTable/OffersTable';

import styles from './BrandIdOffersView.module.css';

import { useBrandIdOffersViewQuery } from './BrandIdOffersView.generated';

export const BrandIdOffersView: React.FC<BrandIdOffersViewProps> = (props) => {
  const { className, brandId } = props;

  const query = useBrandIdOffersViewQuery({
    variables: { brandId, take: 10, skip: 0 },
  });

  const brand = query.data?.brand;

  if (query.loading && !brand) {
    return <Spin size="large" />;
  } else if (!brand) {
    return <NotFoundView />;
  }

  return (
    <div className={classnames(className, styles.root)}>
      <OffersTable
        query={query}
        data={query.data?.brand?.offersPagination}
        activeColumns={[
          'name',
          'categories',
          'status',
          'type',
          'startsAt',
          'endsAt',
        ]}
        title={() => (
          <>
            <I18nLink
              to={{
                pathname: `/offers/create`,
                search: qs.stringify({ brandId }),
              }}
            >
              <Button>Ajouter une offre</Button>
            </I18nLink>{' '}
          </>
        )}
        placeholder={{
          children: (
            <I18nLink
              to={{
                pathname: `/offers/create`,
                search: qs.stringify({ brandId }),
              }}
            >
              <Button>Créer une offre</Button>
            </I18nLink>
          ),
        }}
        filter={{ brand: { id: { is: brandId } } }}
      />
    </div>
  );
};

export type BrandIdOffersViewProps = {
  brandId: string;
  className?: string;
};
