import { useTranslation } from 'react-i18next';
import { Col, Descriptions, Divider, Row, Space } from 'antd';
import Typography from 'antd/lib/typography';
import Link from 'antd/lib/typography/Link';
import Paragraph from 'antd/lib/typography/Paragraph';
import classnames from 'classnames';
import Dinero from 'dinero.js';

import { FileType } from '@graphql/generated/types';

import { BooleanTag } from '@components/atoms/BooleanTag/BooleanTag';
import { PageHeader } from '@molecules/PageHeader/PageHeader';

import styles from './CompanyHeader.module.css';

import {
  CompanyHeaderFragment,
  useCompanyHeaderUpdateMutation,
} from './CompanyHeader.generated';

export const CompanyHeader: React.FC<CompanyHeaderProps> = (props) => {
  const { className, company, showNav } = props;
  const { t } = useTranslation('companies');

  const [mutation] = useCompanyHeaderUpdateMutation();

  const handleUpdate = async (pictureId: string) => {
    await mutation({
      variables: {
        companyId: company.id,
        input: {
          logoId: pictureId,
        },
      },
    });
  };

  return (
    <PageHeader
      logo={company.logo}
      showNav={showNav}
      className={classnames(className, styles.root)}
      uploadLogoProps={{
        fileType: FileType.CompanyLogo,
        onUploadPicture: (pictureId) => handleUpdate(pictureId),
        title: 'Logo',
        picture: company.logo,
        info: 'Résolution recommandée 512x512, max 1mo',
      }}
    >
      <Typography.Title
        level={2}
        ellipsis={{ rows: 2 }}
        className={styles.title}
      >
        {company.name}
      </Typography.Title>
      <Row justify="center" align="middle">
        <Col span={12}>
          <Descriptions column={1} size="small">
            <Descriptions.Item label={t('fields.isDemo')}>
              <BooleanTag value={company.isDemo} />
            </Descriptions.Item>
            <Descriptions.Item label={'id'}>
              <Paragraph style={{ margin: 0 }} copyable>
                {company.id}
              </Paragraph>
            </Descriptions.Item>
            <Descriptions.Item label={t('fields.slug')}>
              <Paragraph style={{ margin: 0 }} copyable>
                {company.slug}
              </Paragraph>
            </Descriptions.Item>
            {!!company.mangopayUser?.defaultWallet && (
              <Descriptions.Item label="Montant du wallet">
                <Space split={<Divider type="vertical" />}>
                  <Paragraph style={{ margin: 0 }}>
                    {Dinero({
                      amount: company.mangopayUser.defaultWallet.balance,
                      currency: 'EUR',
                    })
                      .setLocale('fr-FR')
                      .toFormat('$0,0.00')}
                  </Paragraph>
                  <Link
                    href={`https://dashboard.mangopay.com/User/${company.mangopayUser.mangopayId}/Wallets/${company.mangopayUser.defaultWallet.mangopayId}`}
                    target="_blank"
                  >
                    Voir les transactions
                  </Link>
                </Space>
              </Descriptions.Item>
            )}
          </Descriptions>
        </Col>
      </Row>
    </PageHeader>
  );
};

export type CompanyHeaderProps = {
  className?: string;
  company: CompanyHeaderFragment;
  showNav?: boolean;
};
