import { ApolloError } from '@apollo/client';
import { message } from 'antd';

export function handleMutationErrors(
  err: unknown,
  handledErrors: Record<string, string>,
) {
  if (err instanceof ApolloError) {
    err.graphQLErrors.forEach((err) => {
      if (err.extensions?.code) {
        if (handledErrors[err.extensions.code]) {
          message.error(handledErrors[err.extensions.code]);
        } else {
          message.error(
            `"Une erreur est survenue (${err.extensions.code}). Veuillez contacter le support`,
          );
          message.error(JSON.stringify(err.extensions));
        }
      } else {
        message.error('error:default');
      }
    });
  } else {
    message.error('error:default');
  }
}
