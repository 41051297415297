import { CheckOutlined, CloseOutlined } from '@ant-design/icons';
import { Card, Divider, Space, Switch, Typography } from 'antd';

import {
  useCompanyFeatureConfigBeneficiaryFeatureChildrenDisableMutation,
  useCompanyFeatureConfigBeneficiaryFeatureChildrenEnableMutation,
  useCompanyFeatureConfigBeneficiaryFeatureDisableMutation,
  useCompanyFeatureConfigBeneficiaryFeatureEnableMutation,
  useCompanyFeatureConfigBeneficiaryFeaturePartnerDisableMutation,
  useCompanyFeatureConfigBeneficiaryFeaturePartnerEnableMutation,
  useCompanyFeatureConfigBeneficiaryViewQuery,
} from './CompanyFeatureConfigBeneficiaryView.generated';

interface CompanyFeatureConfigBeneficiaryViewProps {
  companyId: string;
}

export const CompanyFeatureConfigBeneficiaryView = (
  props: CompanyFeatureConfigBeneficiaryViewProps,
) => {
  const { companyId } = props;

  const [featureEnableMutation, featureEnableState] =
    useCompanyFeatureConfigBeneficiaryFeatureEnableMutation({
      variables: { companyId },
    });
  const [featureDisableMutation, featureDisableState] =
    useCompanyFeatureConfigBeneficiaryFeatureDisableMutation({
      variables: { companyId },
    });
  const [childrenDisableMutation, childrenDisableState] =
    useCompanyFeatureConfigBeneficiaryFeatureChildrenDisableMutation({
      variables: { companyId },
    });
  const [childrenEnableMutation, childrenEnableState] =
    useCompanyFeatureConfigBeneficiaryFeatureChildrenEnableMutation({
      variables: { companyId },
    });
  const [partnerDisableMutation, partnerDisableState] =
    useCompanyFeatureConfigBeneficiaryFeaturePartnerDisableMutation({
      variables: { companyId },
    });
  const [partnerEnableMutation, partnerEnableState] =
    useCompanyFeatureConfigBeneficiaryFeaturePartnerEnableMutation({
      variables: { companyId },
    });

  const { data } = useCompanyFeatureConfigBeneficiaryViewQuery({
    variables: { companyId },
  });

  const company = data?.company;
  const feature = company?.featureConfig.beneficiary;

  const handleFeatureToggle = async (checked: boolean) =>
    checked ? featureEnableMutation() : featureDisableMutation();
  const handleChildrenToggle = async (checked: boolean) =>
    checked ? childrenEnableMutation() : childrenDisableMutation();
  const handlePartnerToggle = async (checked: boolean) =>
    checked ? partnerEnableMutation() : partnerDisableMutation();

  const isFeatureLoading =
    featureDisableState.loading || featureEnableState.loading;
  const isChildrenLoading =
    childrenDisableState.loading || childrenEnableState.loading;
  const isPartnerLoading =
    partnerDisableState.loading || partnerEnableState.loading;

  return (
    <div>
      <Space>
        <Switch
          checkedChildren={<CheckOutlined />}
          unCheckedChildren={<CloseOutlined />}
          disabled={!company || isFeatureLoading}
          checked={feature?.isEnabled}
          onChange={handleFeatureToggle}
          loading={featureDisableState.loading || featureEnableState.loading}
        />
        <Typography>Rendre visible l’onglet “ayants droit”</Typography>
      </Space>
      <Divider />
      <Card>
        <Space direction="vertical">
          <Space>
            <Switch
              checkedChildren={<CheckOutlined />}
              unCheckedChildren={<CloseOutlined />}
              disabled={!feature?.isEnabled}
              checked={feature?.canUpdatePartner && feature?.isEnabled}
              onChange={handlePartnerToggle}
              loading={isPartnerLoading}
            />
            <Typography>
              Conjoint(e) - Autoriser la modification (ajout, mise à jour,
              suppression)
            </Typography>
          </Space>
          <Space>
            <Switch
              checkedChildren={<CheckOutlined />}
              unCheckedChildren={<CloseOutlined />}
              disabled={!feature?.isEnabled}
              checked={feature?.canUpdateChildren && feature?.isEnabled}
              onChange={handleChildrenToggle}
              loading={isChildrenLoading}
            />
            <Typography>
              Enfant(s) - Autoriser la modification (ajout, mise à jour,
              suppression){' '}
            </Typography>
          </Space>
        </Space>
      </Card>
    </div>
  );
};
