import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { CheckOutlined, CloseOutlined } from '@ant-design/icons';
import { Button, Divider, Space, Switch, Typography } from 'antd';
import * as Yup from 'yup';

import { Card } from '@components/atoms/Card/Card';
import { FormCheckbox } from '@components/atoms/FormCheckbox/FormCheckbox';
import { FormInputNumber } from '@components/atoms/FormInputNumber/FormInputNumber';
import { FormItem } from '@components/atoms/FormItem/FormItem';
import { Form, useForm } from '@components/organisms/Form/Form';

import {
  useCompanyFeatureConfigGatheringFeatureDisableMutation,
  useCompanyFeatureConfigGatheringFeatureEnableMutation,
  useCompanyFeatureConfigGatheringUpdateMutation,
  useCompanyFeatureConfigGatheringViewQuery,
} from './CompanyFeatureConfigGatheringView.generated';

interface CompanyFeatureConfigGatheringViewProps {
  companyId: string;
}

type CompanyFeatureConfigGatheringViewFormValues = {
  paidGatheringEnabled?: boolean;
  feesMinAmount?: number | null;
  feesMaxAmount?: number | null;
  feesFlatAmount?: number | null;
  feesPercentAmount?: number | null;
};

export const CompanyFeatureConfigGatheringView = (
  props: CompanyFeatureConfigGatheringViewProps,
) => {
  const { companyId } = props;
  const { t } = useTranslation('companies');

  const [featureEnableMutation, featureEnableState] =
    useCompanyFeatureConfigGatheringFeatureEnableMutation();
  const [featureDisableMutation, featureDisableState] =
    useCompanyFeatureConfigGatheringFeatureDisableMutation();
  const [mutation, mutationState] =
    useCompanyFeatureConfigGatheringUpdateMutation();

  const { data } = useCompanyFeatureConfigGatheringViewQuery({
    variables: { companyId },
  });

  const company = data?.company;
  const validationSchema = Yup.object({
    paidGatheringEnabled: Yup.boolean(),
    feesMinAmount: Yup.number().nullable(),
    feesMaxAmount: Yup.number().nullable(),
    feesFlatAmount: Yup.number().nullable(),
    feesPercentAmount: Yup.number().nullable(),
  }).required();
  const form = useForm<CompanyFeatureConfigGatheringViewFormValues>({
    validationSchema,
    defaultValues: {},
  });

  useEffect(() => {
    if (!company) return;

    form.setValue(
      'paidGatheringEnabled',
      company.featureConfig.gathering.isPaidGatheringEnabled,
    );
    form.setValue(
      'feesMinAmount',
      company.featureConfig.gathering.feesMinAmount,
    );
    form.setValue(
      'feesMaxAmount',
      company.featureConfig.gathering.feesMaxAmount,
    );
    form.setValue(
      'feesFlatAmount',
      company.featureConfig.gathering.feesFlatAmount,
    );
    form.setValue(
      'feesPercentAmount',
      company.featureConfig.gathering.feesPercentAmount,
    );
  }, [company]);

  const handleFeatureToggle = async (checked: boolean) => {
    if (checked) {
      await featureEnableMutation({
        variables: { companyId },
      });
    } else {
      await featureDisableMutation({
        variables: { companyId },
      });
    }
  };

  const handleSubmit = async (
    values: CompanyFeatureConfigGatheringViewFormValues,
  ) => {
    await mutation({
      variables: {
        companyId,
        input: {
          paidGatheringEnabled: values.paidGatheringEnabled ?? false,
          ...values,
        },
      },
    });
  };

  const isLoading =
    mutationState.loading ||
    featureDisableState.loading ||
    featureEnableState.loading;

  return (
    <div>
      <Space>
        <Typography>{t('companyFeatureConfig.switch.label')}</Typography>
        <Switch
          checkedChildren={<CheckOutlined />}
          unCheckedChildren={<CloseOutlined />}
          disabled={!company || isLoading}
          checked={company?.featureConfig.gathering.isEnabled}
          onChange={handleFeatureToggle}
          loading={featureDisableState.loading || featureEnableState.loading}
        />
      </Space>
      {company?.featureConfig.gathering.isEnabled && (
        <>
          <Divider />
          <Card>
            <Form
              form={form}
              id="CompanyFeatureConfigGatheringView"
              onValid={handleSubmit}
            >
              <Space direction="vertical">
                <FormItem
                  label={t(
                    'companyFeatureConfig.gathering.fields.paidGatheringEnabled.label',
                  )}
                  name="paidGatheringEnabled"
                  required
                >
                  <FormCheckbox name="paidGatheringEnabled" />
                </FormItem>
                <FormItem
                  label={t(
                    'companyFeatureConfig.gathering.fields.feesMinAmount.label',
                  )}
                  name="feesMinAmount"
                >
                  <FormInputNumber name="feesMinAmount" />
                </FormItem>
                <FormItem
                  label={t(
                    'companyFeatureConfig.gathering.fields.feesMaxAmount.label',
                  )}
                  name="feesMaxAmount"
                >
                  <FormInputNumber name="feesMaxAmount" />
                </FormItem>
                <FormItem
                  label={t(
                    'companyFeatureConfig.gathering.fields.feesFlatAmount.label',
                  )}
                  name="feesFlatAmount"
                >
                  <FormInputNumber name="feesFlatAmount" />
                </FormItem>
                <FormItem
                  label={t(
                    'companyFeatureConfig.gathering.fields.feesPercentAmount.label',
                  )}
                  name="feesPercentAmount"
                >
                  <FormInputNumber name="feesPercentAmount" />
                </FormItem>
                <Button
                  loading={form.formState.isSubmitting || isLoading}
                  type="primary"
                  htmlType="submit"
                >
                  {t('common:action.save')}
                </Button>
              </Space>
            </Form>
          </Card>
        </>
      )}
    </div>
  );
};
