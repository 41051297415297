import { DeepPartial } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Button } from 'antd';
import classnames from 'classnames';
import * as Yup from 'yup';

import {
  CompanyMembershipRole,
  CountryCode,
  UserRole,
} from '@graphql/generated/types';

import { handleMutationErrors } from '@utils/handleMutationErrors';

import { FormCheckbox } from '@components/atoms/FormCheckbox/FormCheckbox';
import { FormInputDate } from '@components/atoms/FormInputDate/FormInputDate';
import { FormSelectCompany } from '@components/atoms/FormSelectCompany/FormSelectCompany';
import { FormSelectCompanyMembershipRole } from '@components/atoms/FormSelectCompanyMembershipRole/FormSelectCompanyMembershipRole';
import { FormSelectUserRole } from '@components/atoms/FormSelectUserRole/FormSelectUserRole';
import { Card } from '@atoms/Card/Card';
import { FormInput } from '@atoms/FormInput/FormInput';
import { FormItem } from '@atoms/FormItem/FormItem';
import { FormSelectCountry } from '@atoms/FormSelectCountry/FormSelectCountry';
import { ChildFormProps, Form, FormProps, useForm } from '@organisms/Form/Form';

import styles from './CompanyMembershipCreateForm.module.css';

import {
  CompanyMembershipCreateFormMutation,
  CompanyMembershipCreateFormMutationVariables,
  useCompanyMembershipCreateFormMutation,
} from './CompanyMembershipCreateForm.generated';

export type CompanyMembershipCreateFormValues = {
  companyId: string;
  companyMembershipRole: CompanyMembershipRole;
  worksite?: string;
  jobTitle?: string;
  contract?: string;
  joinedAt?: Date;
  leftAt?: Date;
  deactivated?: boolean;

  userRole: UserRole;
  email: string;
  firstName: string;
  lastName: string;
  nationality?: CountryCode;
  birthdate?: Date;
  phone?: string;
};

export function CompanyMembershipCreateForm(
  props: CompanyMembershipCreateFormProps,
) {
  const { className, defaultValues = {}, ...rest } = props;

  const { t } = useTranslation('users');

  const validationSchema = Yup.object({
    companyId: Yup.string().required(),
    companyMembershipRole: Yup.mixed()
      .oneOf(Object.values(CompanyMembershipRole))
      .required(),
    worksite: Yup.string(),
    jobTitle: Yup.string(),
    contract: Yup.string(),
    joinedAt: Yup.date(),
    leftAt: Yup.date(),
    deactivated: Yup.boolean(),

    userRole: Yup.mixed().oneOf(Object.values(UserRole)).required(),
    email: Yup.string().required(),
    firstName: Yup.string().required(),
    lastName: Yup.string().required(),
    nationality: Yup.mixed().oneOf(Object.values(CountryCode)),
    birthdate: Yup.date(),
    phone: Yup.string(),
  }).required();

  const form = useForm<CompanyMembershipCreateFormValues>({
    validationSchema,
    defaultValues: {
      companyMembershipRole: CompanyMembershipRole.Member,
      deactivated: false,
      userRole: UserRole.User,
      nationality: CountryCode.Fr,
      ...defaultValues,
    },
  });

  const [mutation] = useCompanyMembershipCreateFormMutation();

  const handleSubmit: FormProps<CompanyMembershipCreateFormValues>['onValid'] =
    async (values) => {
      const variables: CompanyMembershipCreateFormMutationVariables = {
        companyId: values.companyId,
        input: {
          role: values.companyMembershipRole,
          worksite: values.worksite,
          jobTitle: values.jobTitle,
          contract: values.contract,
          joinedAt: values.joinedAt,
          leftAt: values.leftAt,
          deactivated: values.deactivated,
          user: {
            create: {
              email: values.email,
              firstName: values.firstName,
              lastName: values.lastName,
              role: values.userRole,
              nationality: values.nationality,
              birthdate: values.birthdate,
              phone: values.phone,
            },
          },
        },
      };

      try {
        await mutation({ variables });
      } catch (error) {
        handleMutationErrors(error, {});
        throw error;
      }
    };

  const { joinedAt, leftAt } = form.getValues();

  return (
    <Card className={classnames(className, styles.root)}>
      <Form
        id="CompanyMembershipCreateForm"
        className={styles.form}
        onValid={handleSubmit}
        form={form}
        {...rest}
      >
        <FormItem
          className={styles.company}
          required
          label={t('userCreateForm.fields.company.label')}
          name="companyId"
        >
          <FormSelectCompany name="companyId" />
        </FormItem>
        <FormItem
          className={styles.companyMembershipRole}
          required
          label={t('userCreateForm.fields.companyMembershipRole.label')}
          name="companyMembershipRole"
        >
          <FormSelectCompanyMembershipRole name="companyMembershipRole" />
        </FormItem>
        <FormItem
          className={styles.worksite}
          label={t('userCreateForm.fields.worksite.label')}
          name="worksite"
        >
          <FormInput name="worksite" />
        </FormItem>
        <FormItem
          className={styles.jobTitle}
          label={t('userCreateForm.fields.jobTitle.label')}
          name="jobTitle"
        >
          <FormInput name="jobTitle" />
        </FormItem>
        <FormItem
          className={styles.contract}
          label={t('userCreateForm.fields.contract.label')}
          name="contract"
        >
          <FormInput name="contract" />
        </FormItem>
        <FormItem
          className={styles.joinedAt}
          label={t('userCreateForm.fields.joinedAt.label')}
          name="joinedAt"
        >
          <FormInputDate
            disabledDate={(date) => (leftAt ? date.isAfter(leftAt) : false)}
            name="joinedAt"
          />
        </FormItem>
        <FormItem
          className={styles.leftAt}
          label={t('userCreateForm.fields.leftAt.label')}
          name="leftAt"
        >
          <FormInputDate
            disabledDate={(date) =>
              joinedAt ? date.isBefore(joinedAt) : false
            }
            name="leftAt"
          />
        </FormItem>
        <FormItem
          className={styles.deactivated}
          label={t('userCreateForm.fields.deactivated.label')}
          name="deactivated"
        >
          <FormCheckbox name="deactivated" />
        </FormItem>

        <FormItem
          className={styles.email}
          required
          label={t('userCreateForm.fields.email.label')}
          name="email"
        >
          <FormInput name="email" />
        </FormItem>
        <FormItem
          className={styles.firstName}
          required
          label={t('userCreateForm.fields.firstName.label')}
          name="firstName"
        >
          <FormInput name="firstName" />
        </FormItem>
        <FormItem
          className={styles.lastName}
          required
          label={t('userCreateForm.fields.lastName.label')}
          name="lastName"
        >
          <FormInput name="lastName" />
        </FormItem>
        <FormItem
          className={styles.userRole}
          required
          label={t('userCreateForm.fields.userRole.label')}
          name="userRole"
        >
          <FormSelectUserRole name="userRole" />
        </FormItem>
        <FormItem
          className={styles.nationality}
          label={t('userCreateForm.fields.nationality.label')}
          name="nationality"
        >
          <FormSelectCountry name="nationality" />
        </FormItem>
        <FormItem
          className={styles.birthdate}
          label={t('userCreateForm.fields.birthdate.label')}
          name="birthdate"
        >
          <FormInputDate name="birthdate" />
        </FormItem>
        <FormItem
          className={styles.phone}
          label={t('userCreateForm.fields.phone.label')}
          name="phone"
        >
          <FormInput name="phone" />
        </FormItem>
        <div className={styles.footer}>
          <Button
            loading={form.formState.isSubmitting}
            type="primary"
            htmlType="submit"
          >
            {t('userCreateForm.submit')}
          </Button>
        </div>
      </Form>
    </Card>
  );
}

export type CompanyMembershipCreateFormProps = ChildFormProps<
  CompanyMembershipCreateFormValues,
  CompanyMembershipCreateFormMutation
> & {
  className?: string;
  defaultValues?: DeepPartial<CompanyMembershipCreateFormValues>;
};
