import { AppdragMigrationsSynchronizationsTable } from '@components/tables/AppdragMigrationsSynchronizationsTable/AppdragMigrationsSynchronizationsTable';

export const AppdragCompanyMigrationsView = () => {
  return (
    <>
      <AppdragMigrationsSynchronizationsTable
        activeColumns={[
          'id',
          'appdragCompanyId',
          'status',
          'error',
          'createdAt',
        ]}
      />
    </>
  );
};
