import ReactDOM from 'react-dom';
import { Redirect, Route, Router, Switch } from 'react-router-dom';
import { ApolloProvider } from '@apollo/client';
import { ConfigProvider } from 'antd';
import frFR from 'antd/lib/locale/fr_FR';
import { createBrowserHistory } from 'history';
import countries from 'i18n-iso-countries';
import i18nIsoCountryEN from 'i18n-iso-countries/langs/en.json';
import i18nIsoCountryFR from 'i18n-iso-countries/langs/fr.json';

import 'moment/locale/fr';

import { apolloClient } from '@graphql/client';

import { AdminCommentsDrawerProvider } from '@hooks/adminCommentsDrawer/adminCommentsDrawer.hooks';
import { AuthProvider } from '@hooks/auth/auth.hooks';

import './index.less';

import { App } from './App';
import i18n from './i18n';
import reportWebVitals from './reportWebVitals';

countries.registerLocale(i18nIsoCountryFR);
countries.registerLocale(i18nIsoCountryEN);

const locales = i18n.options.supportedLngs as string[];
const base = `/:locale(${locales.join('|')})`;

const history = createBrowserHistory();

ReactDOM.render(
  <Router history={history}>
    <ApolloProvider client={apolloClient}>
      <AuthProvider>
        <ConfigProvider locale={frFR} form={{ requiredMark: 'optional' }}>
          <AdminCommentsDrawerProvider>
            <Switch>
              <Route path={base} component={App} />
              <Redirect to={`/${i18n.options.fallbackLng}`} />
            </Switch>
          </AdminCommentsDrawerProvider>
        </ConfigProvider>
      </AuthProvider>
    </ApolloProvider>
  </Router>,
  document.getElementById('root'),
  handleAppMounted,
);

reportWebVitals();

function handleAppMounted() {
  console.log(
    `GraphQL endpoint: ${process.env.REACT_APP_GRAPHQL_HTTP_ENDPOINT}`,
  );
  console.log(
    `Back-office version: ${process.env.REACT_APP_COMMIT_ID || 'local'}`,
  );
}
