import { Redirect, Route, Switch, useRouteMatch } from 'react-router-dom';

import { SubventionIdView } from './SubventionIdView/SubventionIdView';
import { SubventionReimbursementIdView } from './SubventionReimbursementIdView/SubventionReimbursementIdView';
import { SubventionReimbursementListView } from './SubventionReimbursementListView/SubventionReimbursementListView';
import { SubventionSchemasCreateView } from './SubventionSchemasCreateView/SubventionSchemasCreateView';
import { SubventionSchemasListView } from './SubventionSchemasListView/SubventionSchemasListView';
import { SubventionSchemaView } from './SubventionSchemaView/SubventionSchemaView';
import { SubventionsListView } from './SubventionsListView/SubventionsListView';

export const SubventionRouter = () => {
  const { path, url } = useRouteMatch();
  return (
    <Switch>
      <Route exact path={`${path}/subventions`}>
        <SubventionsListView />
      </Route>

      <Route
        path={`${path}/subventions/:subventionId`}
        component={SubventionIdView}
      />

      <Route exact path={`${path}/schemas`}>
        <SubventionSchemasListView />
      </Route>
      <Route exact path={`${path}/schemas/create`}>
        <SubventionSchemasCreateView />
      </Route>
      <Route
        path={`${path}/schemas/:subventionSchemaId`}
        component={SubventionSchemaView}
      />
      <Route
        path={`${path}/reimbursement/:subventionReimbursementId`}
        component={SubventionReimbursementIdView}
      />
      <Route path={`${path}/reimbursement`}>
        <SubventionReimbursementListView />
      </Route>
      <Redirect to={`${url}/subventions`} />
    </Switch>
  );
};
