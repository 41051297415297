import { useState } from 'react';
import { Button, Form as AntForm, Input, message } from 'antd';
import * as Yup from 'yup';

import { FormInput } from '@components/atoms/FormInput/FormInput';
import { FormItem } from '@components/atoms/FormItem/FormItem';
import { FormTextArea } from '@components/atoms/FormTextArea/FormTextArea';
import {
  ChildFormProps,
  Form,
  FormProps,
  useForm,
} from '@components/organisms/Form/Form';

import {
  SsoProviderSamlCreateMutation,
  SsoProviderSamlCreateMutationVariables,
  useSsoProviderSamlCreateMutation,
} from './SSOProviderSAMLForm.generated';
import { parseSAMLMetadata } from './utils/saml-metadata-parser';

type SSOProviderSAMLFormProps = ChildFormProps<
  SSOProviderSAMLFormValues,
  SsoProviderSamlCreateMutation['ssoProviderSAMLCreate']
> & {
  companyId: string;
  hideFooter?: boolean;
};

type SSOProviderSAMLFormValues =
  SsoProviderSamlCreateMutationVariables['input'];

export function SSOProviderSAMLForm(props: SSOProviderSAMLFormProps) {
  const { companyId, hideFooter, ...rest } = props;
  const [createMutation] = useSsoProviderSamlCreateMutation({});

  const [attributes, setAttributes] = useState<
    { friendlyName?: string; name: string; format: string }[]
  >([]);

  const form = useForm<SSOProviderSAMLFormValues>({
    validationSchema,
  });

  const handleMetadataFileChange: React.ChangeEventHandler<HTMLInputElement> =
    async (e) => {
      const file = e.target.files?.[0];
      if (!file) return;

      const xml = await file.text();
      try {
        const content = await parseSAMLMetadata(xml);

        form.setValue('identityId', content.identityId);
        form.setValue('certificate', content.certificate);
        form.setValue('loginUrl', content.loginUrl);
        form.setValue('logoutUrl', content.logoutUrl);
        form.setValue('namedIdFormats', content.namedIdFormats);
        form.setValue('supportedProtocols', content.supportedProtocols);
        setAttributes(content.attributes);
      } catch (error) {
        if (error instanceof Error) message.error(error.message);

        throw error;
      }
    };

  const handleSubmit: FormProps<SSOProviderSAMLFormValues>['onValid'] = async (
    values,
  ) => {
    const { data } = await createMutation({
      variables: {
        companyId,
        input: values,
      },
      refetchQueries: ['CompanyIdSSOView'],
    });

    return data?.ssoProviderSAMLCreate;
  };

  return (
    <div>
      <div>
        <AntForm layout="vertical">
          <AntForm.Item required label="Metadata file">
            <Input type="file" onChange={handleMetadataFileChange} />
          </AntForm.Item>
        </AntForm>
      </div>
      <hr />
      <Form
        id="SSOProviderSAMLForm"
        form={form}
        onValid={handleSubmit}
        {...rest}
      >
        <FormItem
          label="Nom"
          help="Nom présenter à l'utilisateur eg (Google, Facebook, etc...)"
          name="name"
          required
        >
          <FormInput name="name" />
        </FormItem>
        <FormItem
          label="Supported protocols"
          name="supportedProtocols"
          required
        >
          <FormInput name="supportedProtocols" />
        </FormItem>
        <FormItem label="namedIdFormats" name="namedIdFormats">
          <FormInput name="namedIdFormats" />
        </FormItem>
        <FormItem label="URL de Login" name="loginUrl" required>
          <FormInput name="loginUrl" />
        </FormItem>
        <FormItem label="URL de Logout" name="logoutUrl">
          <FormInput name="logoutUrl" />
        </FormItem>
        <FormItem label="IdentityId" name="identityId" required>
          <FormInput name="identityId" />
        </FormItem>
        <FormItem label="Certificat" name="certificate" required>
          <FormTextArea rows={4} name="certificate" />
        </FormItem>
        <hr />
        {attributes.length > 0 && (
          <code>
            <pre>{JSON.stringify(attributes, null, 2)}</pre>
          </code>
        )}
        <FormItem label="Attribut email" name="emailAttribute">
          <FormInput name="emailAttribute" />
        </FormItem>
        <FormItem label="Attribut prénom" name="firstNameAttribute">
          <FormInput name="firstNameAttribute" />
        </FormItem>
        <FormItem label="Attribut nom de famille" name="lastNameAttribute">
          <FormInput name="lastNameAttribute" />
        </FormItem>
        <FormItem label="Attribut avatar" name="pictureAttribute">
          <FormInput name="pictureAttribute" />
        </FormItem>
        {hideFooter !== true && (
          <div>
            <Button
              loading={form.formState.isSubmitting}
              type="primary"
              htmlType="submit"
            >
              Envoyer
            </Button>
          </div>
        )}
      </Form>
    </div>
  );
}

const validationSchema = Yup.object({
  name: Yup.string().required(),
  identityId: Yup.string().required(),
  certificate: Yup.string().required(),
  loginUrl: Yup.string().url().required(),
  logoutUrl: Yup.string().url().optional(),
  emailAttribute: Yup.string(),
  firstNameAttribute: Yup.string(),
  lastNameAttribute: Yup.string(),
  pictureAttribute: Yup.string(),
  supportedProtocols: Yup.array(Yup.string().required()).required(),
  namedIdFormats: Yup.array(Yup.string().required()),
}).required();
