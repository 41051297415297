import { Button } from 'antd';
import classnames from 'classnames';
import * as Yup from 'yup';

import { OfferCommonFormValues } from '../OfferCommonForm/OfferCommonForm';

import { Card } from '@atoms/Card/Card';
import { Typography } from '@atoms/Typography/Typography';
import { ChildFormProps, Form, FormProps, useForm } from '@organisms/Form/Form';

import styles from './OfferBaseForm.module.css';

import {
  OfferBaseFormCreateMutation,
  OfferBaseFormCreateMutationVariables,
  useOfferBaseFormCreateMutation,
} from './OfferBaseForm.generated';

// eslint-disable-next-line @typescript-eslint/ban-types
export type OfferBaseValues = {};

export function OfferBaseForm(props: OfferBaseProps) {
  const { className, commonValues, onBack, ...rest } = props;
  const validationSchema = Yup.object({}).required();

  const form = useForm<OfferBaseValues>({
    validationSchema,
  });

  const [mutation] = useOfferBaseFormCreateMutation();

  const handleSubmit: FormProps<OfferBaseValues>['onValid'] = async () => {
    const variables: OfferBaseFormCreateMutationVariables = {
      input: {
        brandId: commonValues.brandId,
        campaignId: commonValues.campaignId,
        categoryIds: commonValues.categoryIds,
        localeId: commonValues.localeId,
        url: commonValues.url,
        name: commonValues.name,
        descriptionDelta: commonValues.description,
        storeIds: commonValues.storeIds,
        startsAt: commonValues.startsAt,
        endsAt: commonValues.endsAt,
      },
    };

    const { data } = await mutation({ variables });

    return data;
  };

  return (
    <Card className={classnames(className, styles.root)}>
      <Form
        id="OfferProductForm"
        className={styles.form}
        onValid={handleSubmit}
        form={form}
        {...rest}
      >
        <div className={styles.placeholder}>
          <Typography.Text type="secondary">
            Aucun champ supplémentaire n&apos;est requis pour ce type
            d&apos;offre
          </Typography.Text>
        </div>
        <div className={styles.footer}>
          <Button
            loading={form.formState.isSubmitting}
            type="primary"
            htmlType="submit"
          >
            Créer l&apos;offre
          </Button>
          {!!onBack && (
            <Button
              disabled={form.formState.isSubmitting}
              onClick={onBack}
              htmlType="button"
            >
              Revenir en arrière
            </Button>
          )}
        </div>
      </Form>
    </Card>
  );
}

export type OfferBaseProps = ChildFormProps<
  OfferBaseValues,
  OfferBaseFormCreateMutation
> & {
  className?: string;
  onBack?: () => void;
  commonValues: OfferCommonFormValues;
};
