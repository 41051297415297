import { useTranslation } from 'react-i18next';
import { UpSquareOutlined } from '@ant-design/icons';
import { Button, message } from 'antd';
import * as Yup from 'yup';

import {
  legalNoticeEnPlaceholder,
  legalNoticeFrPlaceholder,
  percentReimbursementNoticeEnPlaceholder,
  percentReimbursementNoticeFrPlaceholder,
} from '../SubventionSchemaCreateForm/SubventionSchemaCreateForm';

import {
  FileType,
  SubventionSchemaAccessType,
  SubventionSchemaStatus,
  SubventionSpendingMode,
  SubventionType,
} from '@graphql/generated/types';

import { Card } from '@components/atoms/Card/Card';
import { FormInput } from '@components/atoms/FormInput/FormInput';
import { FormItem } from '@components/atoms/FormItem/FormItem';
import { FormPicture } from '@components/atoms/FormPicture/FormPicture';
import { FormSelect } from '@components/atoms/FormSelect/FormSelect';
import { FormTextArea } from '@components/atoms/FormTextArea/FormTextArea';
import { Form, FormProps, useForm } from '@components/organisms/Form/Form';

import styles from './SubventionSchemaUpdateForm.module.css';

import {
  SubventionSchemaUpdateFormFragment,
  SubventionSchemaUpdateFormUpdateMutationVariables,
  useSubventionSchemaUpdateFormUpdateMutation,
} from './SubventionSchemaUpdateForm.generated';

export type SubventionSchemaUpdateFormValue = {
  name: string;
  description?: string;
  type: SubventionType[];
  accessType: SubventionSchemaAccessType;
  spendingModes: SubventionSpendingMode[];
  iconId?: string;
  parentSubventionSchemaId?: string;
  reimbursementPolicy?: string | null;
  legalNoticeFr?: string | null;
  legalNoticeEn?: string | null;
  percentReimbursementNoticeFr?: string | null;
  percentReimbursementNoticeEn?: string | null;
};

export const SubventionSchemaUpdateForm = (
  props: SubventionSchemaUpdateFormProps,
) => {
  const { subventionSchema } = props;
  const { t } = useTranslation('subvention-schemas');

  const validationSchema = Yup.object({
    parentSubventionSchemaId: Yup.string(),
    iconId: Yup.string(),
    name: Yup.string().required(),
    description: Yup.string(),
    type: Yup.array(
      Yup.mixed().oneOf(Object.values(SubventionType)).required(),
    ).required(),
    accessType: Yup.mixed()
      .oneOf(Object.values(SubventionSchemaAccessType))
      .required(),
    spendingModes: Yup.array(
      Yup.mixed().oneOf(Object.values(SubventionSpendingMode)).required(),
    )
      .required()
      .min(1),
    reimbursementPolicy: Yup.string(),
    legalNoticeFr: Yup.string().nullable(),
    legalNoticeEn: Yup.string().nullable(),
    percentReimbursementNoticeFr: Yup.string().nullable(),
    percentReimbursementNoticeEn: Yup.string().nullable(),
  }).required();

  const form = useForm<SubventionSchemaUpdateFormValue>({
    validationSchema,
    defaultValues: {
      name: subventionSchema.name,
      description: subventionSchema.description || undefined,
      type: subventionSchema.type,
      accessType: subventionSchema.accessType,
      spendingModes: subventionSchema.spendingModes,
      iconId: subventionSchema.icon?.id,
      parentSubventionSchemaId: subventionSchema.parent?.id,
      reimbursementPolicy: subventionSchema.reimbursementPolicy,
      legalNoticeFr: subventionSchema.translationsPagination?.nodes.find(
        (t) => t.locale === 'fr',
      )?.legalNotice,
      legalNoticeEn: subventionSchema.translationsPagination?.nodes.find(
        (t) => t.locale === 'en',
      )?.legalNotice,
      percentReimbursementNoticeFr:
        subventionSchema.translationsPagination?.nodes.find(
          (t) => t.locale === 'fr',
        )?.percentReimbursementNotice,
      percentReimbursementNoticeEn:
        subventionSchema.translationsPagination?.nodes.find(
          (t) => t.locale === 'en',
        )?.percentReimbursementNotice,
    },
  });

  const [updateMutation] = useSubventionSchemaUpdateFormUpdateMutation();

  const handleSubmit: FormProps<SubventionSchemaUpdateFormValue>['onValid'] =
    async (values) => {
      const variables: SubventionSchemaUpdateFormUpdateMutationVariables = {
        subventionSchemaId: subventionSchema.id,
        input: {
          name: values.name,
          description: values.description?.trim(),
          accessType: values.accessType,
          iconId: values.iconId,
          reimbursementPolicy: values.reimbursementPolicy,
        },
      };

      if (subventionSchema.status === SubventionSchemaStatus.Draft) {
        variables.input.type = values.type;
        variables.input.spendingModes = values.spendingModes;
      }

      if (!variables.input.translations) {
        variables.input.translations = [];
      }

      if (
        values.legalNoticeFr !== undefined ||
        values.percentReimbursementNoticeFr !== undefined
      ) {
        variables.input.translations.push({
          localeId: 'fr',
          legalNotice: values.legalNoticeFr?.trim() || null,
          percentReimbursementNotice:
            values.percentReimbursementNoticeFr?.trim() || null,
        });
      }

      if (
        values.legalNoticeEn !== undefined ||
        values.percentReimbursementNoticeEn !== undefined
      ) {
        variables.input.translations.push({
          localeId: 'en',
          legalNotice: values.legalNoticeEn?.trim() || null,
          percentReimbursementNotice:
            values.percentReimbursementNoticeEn?.trim() || null,
        });
      }

      try {
        const { data } = await updateMutation({ variables });
        message.success('Formulaire enregistré');
        return data;
      } catch {
        message.error('Une erreur est survenue');
      }
    };

  const formValues = form.getValues();

  return (
    <Card>
      <Form id="SubventionSchemaUpdateForm" form={form} onValid={handleSubmit}>
        <FormItem required label={t('fields.name.label')} name="name">
          <FormInput name="name" />
        </FormItem>
        <FormItem
          required
          label={t('fields.description.label')}
          name="description"
        >
          <FormInput name="description" />
        </FormItem>
        <FormItem
          className={styles.accessType}
          required
          label={t('fields.accessType.label')}
          name="accessType"
        >
          <FormSelect
            name="accessType"
            options={[
              {
                label: t('fields.accessType.values.open'),
                value: SubventionSchemaAccessType.Open,
              },
              {
                label: t('fields.accessType.values.whitelist'),
                value: SubventionSchemaAccessType.Whitelist,
              },
            ]}
          />
        </FormItem>
        <FormItem
          className={styles.spendingModes}
          required
          label={t('fields.spendingModes.label')}
          name="spendingModes"
        >
          <FormSelect
            name="spendingModes"
            multiple
            disabled={subventionSchema.status !== SubventionSchemaStatus.Draft}
            options={[
              {
                label: t('fields.spendingModes.values.app'),
                value: SubventionSpendingMode.App,
              },
              {
                label: t('fields.spendingModes.values.reimbursement'),
                value: SubventionSpendingMode.Reimbursement,
              },
            ]}
          />
        </FormItem>
        <FormItem required label={t('fields.type.label')} name="type">
          <FormSelect
            name="type"
            multiple
            disabled={subventionSchema.status !== SubventionSchemaStatus.Draft}
            options={[
              {
                label: t('fields.type.values.fund'),
                value: SubventionType.Fund,
              },
              {
                label: t('fields.type.values.discount'),
                value: SubventionType.Discount,
              },
            ]}
          />
        </FormItem>
        <FormItem
          className={styles.reimbursementPolicy}
          label={t('fields.reimbursementPolicy.label')}
          name="reimbursementPolicy"
        >
          <FormTextArea rows={5} name="reimbursementPolicy" />
        </FormItem>
        <FormItem
          className={styles.percentReimbursementNotice}
          label={t('fields.percentReimbursementNotice.fr')}
          name="percentReimbursementNoticeFr"
        >
          <FormTextArea
            rows={4}
            name="percentReimbursementNoticeFr"
            placeholder={percentReimbursementNoticeFrPlaceholder}
          />
        </FormItem>
        <FormItem
          className={styles.percentReimbursementNotice}
          label={t('fields.percentReimbursementNotice.en')}
          name="percentReimbursementNoticeEn"
        >
          <FormTextArea
            rows={4}
            name="percentReimbursementNoticeEn"
            placeholder={percentReimbursementNoticeEnPlaceholder}
          />
        </FormItem>
        <FormItem
          className={styles.legalNotice}
          label={t('fields.legalNotice.fr')}
          name="legalNoticeFr"
        >
          <FormTextArea
            rows={4}
            name="legalNoticeFr"
            placeholder={legalNoticeFrPlaceholder}
            style={{ marginBottom: 10 }}
          />

          <Button
            size="small"
            icon={<UpSquareOutlined />}
            onClick={() => {
              form.setValue('legalNoticeFr', legalNoticeFrPlaceholder);
            }}
          >
            Appliquer le modèle français par défaut
          </Button>
        </FormItem>
        <FormItem
          className={styles.legalNotice}
          label={t('fields.legalNotice.en')}
          name="legalNoticeEn"
        >
          <FormTextArea
            rows={4}
            name="legalNoticeEn"
            placeholder={legalNoticeEnPlaceholder}
            style={{ marginBottom: 10 }}
          />

          <Button
            size="small"
            icon={<UpSquareOutlined />}
            onClick={() => {
              form.setValue('legalNoticeEn', legalNoticeEnPlaceholder);
            }}
          >
            Appliquer le modèle anglais par défaut
          </Button>
        </FormItem>
        {!formValues.parentSubventionSchemaId && (
          <FormItem
            className={styles.icon}
            required
            label={t('fields.icon.label')}
            name="iconId"
          >
            <FormPicture
              name="iconId"
              previewRatio="1:1"
              type={FileType.SubventionSchemaIcon}
              info="Résolution recommandée 256x256, max 512ko"
            />
          </FormItem>
        )}
        <div className={styles.footer}>
          <Button
            loading={form.formState.isSubmitting}
            type="primary"
            htmlType="submit"
          >
            {t('forms.update.submit')}
          </Button>
        </div>
      </Form>
    </Card>
  );
};

export type SubventionSchemaUpdateFormProps = {
  subventionSchema: SubventionSchemaUpdateFormFragment;
};
