import { useTranslation } from 'react-i18next';
import { Button, message } from 'antd';
import * as Yup from 'yup';

import { Card } from '@components/atoms/Card/Card';
import { FormInput } from '@components/atoms/FormInput/FormInput';
import { FormItem } from '@components/atoms/FormItem/FormItem';
import { Form, FormProps, useForm } from '@components/organisms/Form/Form';

import { useSorewardsUserInsertMutation } from './SorewardsInsertMigrationView.generated';

export type SorewardsInsertFormValues = {
  email: string;
};

export const SorewardsInsertView = () => {
  const { t } = useTranslation('tools');
  const [mutation] = useSorewardsUserInsertMutation();

  const validationSchema = Yup.object({
    email: Yup.string().required(),
  });

  const form = useForm<SorewardsInsertFormValues>({
    validationSchema,
  });

  const handleSubmit: FormProps<SorewardsInsertFormValues>['onValid'] = async (
    values,
  ) => {
    const variables = {
      email: values.email,
    };

    try {
      await mutation({ variables });
      form.reset();
      message.success(t('common:messages.success.created'));
    } catch (error) {
      console.error(error);
      const errorMessage =
        error instanceof Error
          ? error.message
          : t('common:messages.error.unknown');
      message.error(errorMessage);
      form.setError('email', { message: errorMessage }, { shouldFocus: true });
    }

    return;
  };

  return (
    <Card>
      <Form id="UserUpdateForm" onValid={handleSubmit} form={form}>
        <FormItem
          required
          label={t('sorewards-insert.form.fields.email')}
          name="email"
        >
          <FormInput name="email" />
        </FormItem>
        <div>
          <Button
            loading={form.formState.isSubmitting}
            type="primary"
            htmlType="submit"
          >
            {t('sorewards-insert.form.submit')}
          </Button>
        </div>
      </Form>
    </Card>
  );
};
