/* eslint-disable @typescript-eslint/no-explicit-any */
import React, {
  cloneElement,
  isValidElement,
  ReactNode,
  useState,
} from 'react';
import { Button, Modal as AntModal } from 'antd';
import classnames from 'classnames';

import styles from './Modal.module.css';

export const Modal: React.FC<ModalProps> = (props) => {
  const {
    className,
    children,
    title,
    width,
    isOpen,
    form,
    onClose,
    onSubmit,
    footer,
  } = props;
  const [isLoading, setLoading] = useState(false);

  const onSubmitFactory =
    (child: ReturnType<typeof cloneElement>) =>
    async (...rest: any[]) => {
      setLoading(true);
      await callChildMethod(child, 'onSubmit', ...rest);
    };

  const onSuccessFactory =
    (child: ReturnType<typeof cloneElement>) =>
    async (...rest: any[]) => {
      setLoading(false);
      await callChildMethod(child, 'onSuccess', ...rest);
      onClose();
    };

  const onFailureFactory =
    (child: ReturnType<typeof cloneElement>) =>
    async (...rest: any[]) => {
      setLoading(false);
      await callChildMethod(child, 'onFailure', ...rest);
    };

  return (
    <AntModal
      className={classnames(className, styles.root)}
      open={isOpen}
      destroyOnClose
      onCancel={onClose}
      title={title}
      width={width}
      footer={
        footer || [
          <Button onClick={onClose} key="cancel" htmlType="button">
            Annuler
          </Button>,
          <Button
            loading={isLoading}
            onClick={form ? undefined : onSubmit}
            type="primary"
            key="submit"
            htmlType="submit"
            form={form}
          >
            Envoyer
          </Button>,
        ]
      }
    >
      {isValidElement(children)
        ? cloneElement(children, {
            hideFooter: true,
            onSubmit: onSubmitFactory(children),
            onSuccess: onSuccessFactory(children),
            onFailure: onFailureFactory(children),
          })
        : children}
    </AntModal>
  );
};

export type ModalProps = {
  className?: string;
  title: string;
  width?: string | number;
  children: ReactNode;
  isOpen: boolean;
  onClose: () => void;
  onSubmit?: () => void;
  form?: string;
  footer?: React.ReactNode;
};

async function callChildMethod(
  child: ReturnType<typeof cloneElement>,
  method: string,
  ...rest: any[]
) {
  if (isValidElement(child)) {
    const childProps = (child as React.ReactElement<any>).props;

    if (childProps[method]) {
      return await childProps[method](...rest);
    }
  }
}
