import { ApolloClient, from, HttpLink, InMemoryCache } from '@apollo/client';
import { withScalars } from 'apollo-link-scalars';
import { format } from 'date-fns';
import { buildClientSchema, IntrospectionQuery } from 'graphql';

import { possibleTypes } from './generated/fragment-matcher.json';
import introspectionResult from './generated/schema.json';
import { i18nLink } from './links/i18n.link';
import { namedLink } from './links/named.link';
import { unauthorizedErrorLink } from './links/unauthorized.link';
import { typePolicies } from './typePolicies';

const httpLink = new HttpLink({
  uri: process.env.REACT_APP_GRAPHQL_HTTP_ENDPOINT,
  fetch,
  credentials: 'include',
});

const schema = buildClientSchema(
  introspectionResult as unknown as IntrospectionQuery,
);

export const apolloClient = new ApolloClient({
  name: 'app-admin',
  cache: new InMemoryCache({
    typePolicies,
    possibleTypes,
  }),
  link: from([
    withScalars({
      schema,
      typesMap: {
        DateTime: {
          serialize: (date: Date) => date.toISOString(),
          parseValue: (date: string) => new Date(date),
        },
        Date: {
          serialize: (date: Date) => format(date, 'yyyy-MM-dd'),
          parseValue: (date: string) => new Date(date),
        },
      },
    }),
    i18nLink,
    unauthorizedErrorLink,
    namedLink,
    httpLink,
  ]),
  defaultOptions: {
    query: {
      fetchPolicy: 'network-only',
    },
    watchQuery: {
      fetchPolicy: 'cache-and-network',
    },
  },
});
